import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DatePicker from "react-datepicker";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Checkbox from "@mui/material/Checkbox";
import { defaultUserSelectedFilter } from "../pages/Dashboard/data";
import {
  handleChildFilterUnchecked,
  handleParentClick,
  filterUncheckedForDateCt,
  handleDateChange,
  findUpdatedSuboptionsList,
  updateUrlWithFilters,
} from "./clearAllSelectedFilters";

export const clearAllSelectedFiltersForDashbooard = (
  setUserSelectedFilter,
  setFilterOptions,
  filterOptions,
  removeCheckFromAllSuboptions
) => {
  setUserSelectedFilter((userSelectedFilter) => {
    return {
      ...defaultUserSelectedFilter,
      dashboardDate: userSelectedFilter.dashboardDate,
    };
  });

  setFilterOptions(
    filterOptions.map((filterOption) => {
      if (filterOption.type === "dashboardDate") {
        return filterOption;
      }
      return {
        ...filterOption,
        checked: false,
        suboptions: removeCheckFromAllSuboptions(
          filterOption.suboptions,
          filterOption.type
        ),
      };
    })
  );

  window.history.replaceState(null, "", window.location.pathname);
};

export const implementSetUserSelectedFilter = (
  userSelectedFilter,
  selectedSubType
) => {
  return {
    ...userSelectedFilter,
    dashboardDate: {
      ...userSelectedFilter["dashboardDate"],
      [selectedSubType]: {
        From: "",
        To: "",
      },
    },
  };
};

export function updateUserSelectedFilter({
  e,
  parentFilter,
  indices,
  sub,
  callbacks,
  state,
}) {
  const { parentFilterType, childFilterId, childFilterType } = parentFilter;
  const { parentFilterIndex, childFilterIndex } = indices;
  const {
    handleChildFilterUnchecked,
    setFilterOptions,
    setUserSelectedFilter,
  } = callbacks;
  const { userSelectedFilter, filterOptions } = state;

  if (sub.checked === true) {
    handleChildFilterUnchecked(
      e,
      parentFilterType,
      sub,
      filterOptions,
      setFilterOptions,
      userSelectedFilter,
      setUserSelectedFilter
    );
    return;
  }

  const newUserSelectedFilter = {
    ...userSelectedFilter,
    [parentFilterType]: [
      ...userSelectedFilter[parentFilterType],
      childFilterId,
    ],
  };

  setFilterOptions(
    filterOptions.map((item, index) =>
      index === parentFilterIndex
        ? {
            ...item,
            checked: true,
            isHighlighted: true,
            suboptions: findUpdatedSuboptionsList(
              item.suboptions,
              childFilterIndex,
              parentFilterType
            ),
          }
        : { ...item, isHighlighted: false }
    )
  );

  setUserSelectedFilter(newUserSelectedFilter);

  updateUrlWithFilters(newUserSelectedFilter);
}

export const FilterSubOptions = ({
  handleOnDateHover,
  handleChildClick,
  filter,
  sub,
  childIndex,
  index,
  findChildFilterSubElements,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter,
}) => {
  const isCustomDate = sub.type === "Custom date";

  const onMouseOverHandler = (e) => {
    if (isCustomDate) {
      handleOnDateHover(e, sub.type);
    }
  };

  const onClickHandler = (e) => {
    if (isCustomDate || filter.type !== "Custom date") {
      handleChildClick(
        e,
        filter.type,
        sub.id,
        index,
        childIndex,
        sub,
        sub.type
      );
    }
  };

  return (
    <div
      onMouseOver={onMouseOverHandler}
      className="sub_filter_checkbox"
      style={{ paddingLeft: "0.8rem" }}
      onClick={onClickHandler}
    >
      {findChildFilterSubElements(
        filter.type,
        sub,
        filterOptions,
        setFilterOptions,
        userSelectedFilter,
        setUserSelectedFilter
      )}
    </div>
  );
};

export const findSubOptionsStatus = (suboptions, subType) =>
  suboptions.map((item) =>
    item.type === subType ? { ...item, checked: false } : item
  );

export const checkIfParentFilterIsChecked = (suboptions) =>
  suboptions.filter((item) => item.checked).length > 0;

export const implementSetFilterOptions = (filterOptions, key, subType) =>
  filterOptions.map((filterOption) =>
    filterOption.type === key
      ? {
          ...filterOption,
          checked: checkIfParentFilterIsChecked(filterOption.suboptions),
          suboptions: findSubOptionsStatus(filterOption.suboptions, subType),
        }
      : filterOption
  );

export const removeSelectedFilterForDate = (
  setUserSelectedFilter,
  selectedSubType,
  filterOptions,
  setFilterOptions
) => {
  setUserSelectedFilter((userSelectedFilter) => {
    return implementSetUserSelectedFilter(userSelectedFilter, selectedSubType);
  });

  setFilterOptions((filterOptions) => {
    return implementSetFilterOptions(
      filterOptions,
      "dashboardDate",
      selectedSubType
    );
  });
};

export const getDateFilterChipStyle = () => {
  return {
    [`& .MuiChip-deleteIcon`]: {
      color: "var(--Base-Purple-5, #651FFF)",
      fontFamily: "Rubik, sans-serif",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "1.25rem",
      letterSpacing: "0.00875rem",
      marginBottom: "-0.15rem",
    },
  };
};

export const formatDateValue = (dashboardDate) => {
  const selectedDate = new Date(dashboardDate);
  const fullYear = selectedDate.getFullYear();
  const month = selectedDate.getMonth() + 1;
  const day = selectedDate.getDate();

  const updatedMonth = month < 10 ? `0${month}` : month;
  const updatedDate = day < 10 ? `0${day}` : day;

  return `${fullYear}-${updatedMonth}-${updatedDate}`;
};

export const handleParentFilterUnchecked = (
  e,
  filter,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  filterUncheckedForDateCt(
    filter,
    setFilterOptions,
    filterOptions,
    userSelectedFilter,
    setUserSelectedFilter
  );

  if (filter.type === "dashboardDate") {
    filterUncheckedForDateCt(
      filter.type,
      setFilterOptions,
      userSelectedFilter,
      setUserSelectedFilter
    );
  } else if (filter.type === "closed tickets") {
    let tempUserSelectedFilter = userSelectedFilter;

    delete tempUserSelectedFilter["closed tickets"];

    setFilterOptions((filterOptions) => {
      return filterOptions.map((filterOption) => {
        if (filterOption.type === "closed tickets") {
          return {
            ...filterOption,
            checked: false,
          };
        } else {
          return filterOption;
        }
      });
    });

    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...tempUserSelectedFilter,
      };
    });
  } else if (filter.type === "open tickets") {
    let tempUserSelectedFilter = userSelectedFilter;

    delete tempUserSelectedFilter["open tickets"];

    setFilterOptions((filterOptions) => {
      return filterOptions.map((filterOption) => {
        if (filterOption.type === "open tickets") {
          return {
            ...filterOption,
            checked: false,
          };
        } else {
          return filterOption;
        }
      });
    });

    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...tempUserSelectedFilter,
      };
    });
  } else console.log("HandleParentFalse");

  e.stopPropagation();
};

const generateCustomDateStrings = (
  userSelectedFilter,
  item,
  dateFilterType,
  afterQueryParam,
  beforeQueryParam
) => {
  return Object.keys(userSelectedFilter[item][dateFilterType])
    .map((itemKey) => {
      const value = userSelectedFilter[item][dateFilterType][itemKey];
      if (itemKey === "From" && value.length > 0) {
        return `&${afterQueryParam}=${value}`;
      } else if (itemKey === "To" && value.length > 0) {
        return `&${beforeQueryParam}=${value}`;
      } else {
        return "";
      }
    })
    .join("");
};

const generateDateRangeString = (
  userSelectedFilter,
  item,
  dateFilterType,
  afterQueryParam,
  beforeQueryParam,
  noOfDays
) => {
  const fromData = userSelectedFilter[item][dateFilterType]["From"];

  if (fromData.length > 0) {
    const fromDate = userSelectedFilter[item][dateFilterType]["From"];
    const toDate = addDaysToDateString(fromDate, noOfDays);
    return `&${afterQueryParam}=${fromDate}&${beforeQueryParam}=${toDate}`;
  } else {
    return "";
  }
};

const generateDateStrings = (
  userSelectedFilter,
  item,
  dateFilterType,
  afterQueryParam
) => {
  const fromData = userSelectedFilter[item][dateFilterType]["From"];

  if (fromData.length > 0) {
    return `&${afterQueryParam}=${fromData}`;
  } else {
    return "";
  }
};

const generateAssigneesString = (userSelectedFilter, item) => {
  return userSelectedFilter[item].length > 0
    ? `&${item}=${userSelectedFilter[item].join(",")}`
    : "";
};

const generateTeamsOrSubTeamsString = (userSelectedFilter, item) => {
  let updatedTeam = "";
  if (item === "teams") updatedTeam = "team";
  else if (item === "sub_teams") updatedTeam = "sub_team";
  return userSelectedFilter[item].length > 0
    ? userSelectedFilter[item]
        .map((itemType) => `&${updatedTeam}=${itemType}`)
        .join("")
    : "";
};

export const generateParamString = (
  userSelectedFilter,
  handleSetBeforeParamString,
  useCreatedDates = false
) => {
  let pstr = "";

  Object.keys(userSelectedFilter).forEach((item) => {
    switch (item) {
      case "assignees":
        pstr += generateAssigneesString(userSelectedFilter, item);
        break;

      case "teams":
      case "sub_teams":
        pstr += generateTeamsOrSubTeamsString(userSelectedFilter, item);
        break;

      case "dashboardDate": {
        const afterDateKey = useCreatedDates
          ? "created_date_after"
          : "completed_date_after";
        const beforeDateKey = useCreatedDates
          ? "created_date_before"
          : "completed_date_before";

        pstr += [
          generateCustomDateStrings(
            userSelectedFilter,
            item,
            "Custom date",
            afterDateKey,
            beforeDateKey
          ),
          generateDateStrings(userSelectedFilter, item, "Today", afterDateKey),
          generateDateStrings(
            userSelectedFilter,
            item,
            "Yesterday",
            afterDateKey
          ),
          generateDateRangeString(
            userSelectedFilter,
            item,
            "Last 7 days",
            afterDateKey,
            beforeDateKey,
            7
          ),
          generateDateRangeString(
            userSelectedFilter,
            item,
            "Last 14 days",
            afterDateKey,
            beforeDateKey,
            14
          ),
          generateDateRangeString(
            userSelectedFilter,
            item,
            "Last 30 days",
            afterDateKey,
            beforeDateKey,
            30
          ),
        ].join("");

        break;
      }

      case "closed tickets":
        pstr +=
          userSelectedFilter[item].length > 0 ? `&ticket_type=closed` : "";
        break;

      case "open tickets":
        pstr += userSelectedFilter[item].length > 0 ? `&ticket_type=open` : "";
        break;

      default:
        break;
    }
  });

  if (handleSetBeforeParamString) {
    handleSetBeforeParamString(pstr);
  }

  return pstr;
};

export const checkRelevantDateSubtype = (suboptions, dateType) => {
  return suboptions.map((suboption) => {
    if (suboption.type === dateType) {
      return {
        ...suboption,
        checked: true,
      };
    } else
      return {
        ...suboption,
        checked: false,
      };
  });
};

export const findDashboardDateValue = (childFiltertype) => {
  if (childFiltertype === "Today")
    return subtractDaysToDateString(new Date(), 0);
  else if (childFiltertype === "Yesterday") {
    return subtractDaysToDateString(new Date(), 1);
  } else if (childFiltertype === "Last 7 days") {
    return subtractDaysToDateString(new Date(), 7);
  } else if (childFiltertype === "Last 14 days") {
    return subtractDaysToDateString(new Date(), 14);
  } else if (childFiltertype === "Last 30 days") {
    return subtractDaysToDateString(new Date(), 30);
  }
};

export const handleDateCreatedDueDate = (
  e,
  childFilter,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  if (childFilter.type === "Custom date") {
    return;
  }

  const dashboardDate = findDashboardDateValue(childFilter.type);
  const suboptionType = "From";

  childFilter.showsuboptions = true;

  handleDateChange(
    formatDateValue(dashboardDate),
    suboptionType,
    childFilter.type,
    filterOptions,
    setFilterOptions,
    userSelectedFilter,
    setUserSelectedFilter
  );
};

export const subFilterAssigneeNameStyle = (userName) => {
  return String(userName).charAt(0);
};

export const subFilterNameStyle = (subFilterName) => {
  return subFilterName.charAt(0).toUpperCase() + subFilterName.slice(1);
};

export const formatDateFilterDropdown = (dateString) => {
  if (dateString) {
    const dashboardDate = new Date(dateString);
    const day =
      dashboardDate.getDate() < 10
        ? `0${dashboardDate.getDate()}`
        : dashboardDate.getDate();
    const month =
      dashboardDate.getMonth() + 1 < 10
        ? `0${dashboardDate.getMonth() + 1}`
        : dashboardDate.getMonth() + 1;
    const year = dashboardDate.getFullYear().toString().slice(2);

    return `${day}/${month}/${year}`;
  }
  return "";
};

const getNormalDatePicker = (
  userSelectedFilter,
  childFilter,
  suboption,
  filterOptions,
  setFilterOptions,
  setUserSelectedFilter
) => {
  const maxDateValue = "";
  const minDateValue = "";

  const minMaxDates = {
    maxDateValue,
    minDateValue,
  };

  return getDatePicker(
    minMaxDates,
    userSelectedFilter,
    childFilter,
    suboption,
    filterOptions,
    setFilterOptions,
    setUserSelectedFilter
  );
};

const addDaysToDateString = (dateString, noOfdays) => {
  const date = new Date(dateString);

  date.setDate(date.getDate() + noOfdays);

  const formattedDate = date.toISOString().slice(0, 10);

  return formattedDate;
};

const subtractDaysToDateString = (dateString, noOfDays) => {
  const date = new Date(dateString);

  date.setDate(date.getDate() - noOfDays);

  const formattedDate = date.toISOString().slice(0, 10);

  return formattedDate;
};

const getDatePicker = (
  minMaxDates,
  userSelectedFilter,
  childFilter,
  suboption,
  filterOptions,
  setFilterOptions,
  setUserSelectedFilter
) => {
  return (
    <DatePicker
      maxDate={minMaxDates.maxDateValue}
      // minDate={new Date(userSelectedFilter['dashboardDate']['Custom date']['From']).toISOString().slice(0,10)}
      minDate={minMaxDates.minDateValue}
      selected={
        userSelectedFilter["dashboardDate"][`${childFilter.type}`][
          `${suboption.type}`
        ]
      }
      // className="custom-date-picker"
      customInput={
        // <div className="datePickerBtnContainer">
        //   <div className="calendar_icon">
        //     <CalendarTodayOutlinedIcon />
        //   </div>
        //   <div className="date_picker_type">
        //     {suboption.type}
        //   </div>
        //   <div className="date_picker_value">
        //     {
        //       formatDateFilterDropdown(userSelectedFilter["dashboardDate"][`${childFilter.type}`][
        //         `${suboption.type}`
        //       ])
        //     }
        //   </div>
        // </div>
        <div className="datePickerBtnContainer">
          <div className="calendar_icon">
            <CalendarTodayOutlinedIcon />
          </div>
          <div className="date_picker_type">{suboption.type}</div>
          <div className="date_picker_value">
            {formatDateFilterDropdown(
              userSelectedFilter["dashboardDate"][`${childFilter.type}`][
                `${suboption.type}`
              ]
            )}
          </div>
        </div>
      }
      onChange={(dashboardDate) => {
        handleDateChange(
          formatDateValue(dashboardDate),
          suboption.type,
          childFilter.type,
          filterOptions,
          setFilterOptions,
          userSelectedFilter,
          setUserSelectedFilter
        );
      }}
    />
  );
};

const getMaxRestrictedDatePicker = (
  userSelectedFilter,
  childFilter,
  suboption,
  filterOptions,
  setFilterOptions,
  setUserSelectedFilter
) => {
  const maxDateValue = addDaysToDateString(
    userSelectedFilter["dashboardDate"]["Custom date"]["From"],
    31
  );
  const minDateValue = addDaysToDateString(
    userSelectedFilter["dashboardDate"]["Custom date"]["From"],
    1
  );

  const minMaxDates = {
    maxDateValue,
    minDateValue,
  };

  return getDatePicker(
    minMaxDates,
    userSelectedFilter,
    childFilter,
    suboption,
    filterOptions,
    setFilterOptions,
    setUserSelectedFilter
  );
};

const getMinRestrictedDatePicker = (
  userSelectedFilter,
  childFilter,
  suboption,
  filterOptions,
  setFilterOptions,
  setUserSelectedFilter
) => {
  const minDateValue = subtractDaysToDateString(
    userSelectedFilter["dashboardDate"]["Custom date"]["To"],
    31
  );
  const maxDateValue = subtractDaysToDateString(
    new Date(userSelectedFilter["dashboardDate"]["Custom date"]["To"]),
    1
  );
  const minMaxDates = {
    minDateValue,
    maxDateValue,
  };

  return getDatePicker(
    minMaxDates,
    userSelectedFilter,
    childFilter,
    suboption,
    filterOptions,
    setFilterOptions,
    setUserSelectedFilter
  );
};

const getDatePickerWithRestrictions = (
  dateType,
  userSelectedFilter,
  childFilter,
  suboption,
  filterOptions,
  setFilterOptions,
  setUserSelectedFilter
) => {
  const fromValue = userSelectedFilter["dashboardDate"]["Custom date"]["From"];
  const toValue = userSelectedFilter["dashboardDate"]["Custom date"]["To"];

  if (fromValue.length === 0 && toValue.length === 0) {
    return getNormalDatePicker(
      userSelectedFilter,
      childFilter,
      suboption,
      filterOptions,
      setFilterOptions,
      setUserSelectedFilter
    );
  } else if (
    (fromValue.length > 0 && toValue.length === 0) ||
    (fromValue.length > 0 && toValue.length > 0 && dateType === "To")
  ) {
    return getMaxRestrictedDatePicker(
      userSelectedFilter,
      childFilter,
      suboption,
      filterOptions,
      setFilterOptions,
      setUserSelectedFilter
    );
  } else if (
    (fromValue.length === 0 && toValue.length > 0) ||
    (fromValue.length > 0 && toValue.length > 0 && dateType === "From")
  ) {
    return getMinRestrictedDatePicker(
      userSelectedFilter,
      childFilter,
      suboption,
      filterOptions,
      setFilterOptions,
      setUserSelectedFilter
    );
  }
};

export const findChildFilterSubElements = (
  parentFilterType,
  childFilter,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  if (parentFilterType === "dashboardDate") {
    return (
      <div className="date_main_container">
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleDateCreatedDueDate(
              e,
              childFilter,
              filterOptions,
              setFilterOptions,
              userSelectedFilter,
              setUserSelectedFilter
            );
          }}
          className="date-sub-menu-container"
        >
          <div className={`${childFilter.type}-sub-menu-text`}>
            {childFilter.checked ? (
              <div className="single_selection_sub_filter">
                {childFilter.type}
              </div>
            ) : (
              childFilter.type
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {["Date created", "Date due", "Custom date"].includes(
              childFilter.type
            ) && <ChevronRightIcon />}
          </div>
        </div>
        {childFilter.showsuboptions && childFilter.type === "Custom date" && (
          <div className="fromTo">
            {childFilter.suboptions.map((suboption) => {
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="date_last_level"
                >
                  {getDatePickerWithRestrictions(
                    suboption.type,
                    userSelectedFilter,
                    childFilter,
                    suboption,
                    filterOptions,
                    setFilterOptions,
                    setUserSelectedFilter
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="assignee_sub_filter">
      {parentFilterType === "assignees" ? (
        <div
          className="firstLetter"
          style={{
            width: "28px",
            height: "28px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bolder",
            borderRadius: "28px",
            color: "white",
            backgroundColor: `${childFilter.colour}`,
          }}
        >
          {subFilterAssigneeNameStyle(childFilter.type)}
        </div>
      ) : (
        ""
      )}
      <div
        style={childFilter.checked ? { fontWeight: "600" } : {}}
        className={childFilter.checked ? "assignee-value" : "assignee_value"}
      >
        {subFilterNameStyle(childFilter.type)}
      </div>
      <div>
        <Checkbox
          checked={childFilter.checked}
          onClick={(e) => {
            childFilter.checked
              ? handleChildFilterUnchecked(
                  e,
                  parentFilterType,
                  childFilter,
                  filterOptions,
                  setFilterOptions,
                  userSelectedFilter,
                  setUserSelectedFilter
                )
              : handleParentClick(
                  e,
                  parentFilterType,
                  childFilter,
                  filterOptions,
                  setFilterOptions,
                  userSelectedFilter,
                  setUserSelectedFilter
                );
          }}
          sx={{
            color: "rgba(192, 195, 206, 1)",
            "&.Mui-checked": {
              color: "rgba(98, 0, 234, 1)",
            },
          }}
          className="chip_checkbox"
        />
      </div>
    </div>
  );
};
