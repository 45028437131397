import { Tooltip } from "@mui/material";
import LinearProgressDeterminate from "../LinearProgressDeterminate";
import OnTrackDashboard from "../../assets/OnTrack_Dashboard_card.svg";
import BreachedDashboard from "../../assets/Breached_Dashboard_card.svg";
import AtRiskDashboard from "../../assets/AtRisk_Dashboard_card.svg";
import PausedDashboard from "../../assets/Paused_Dashboard_card.svg";
import AchievedDashboard from "../../assets/Check.svg";
import InformationCircle from "../../assets/information-circle-2.svg";
import { generateToolTipTitle } from "./CardsFunctions";

const getTicketsHeadingContent = (cardType) => {
  if (cardType === "First response SLA") return "First response met";
  else return `Tickets ${cardType.toLowerCase()}`;
};

const generateTicketSlaStatusBadge = (cardType) => {
  if (cardType === "On Track")
    return (
      <div
        className="sla_badge"
        style={{
          background: "var(--Base-Green-5, #00C853)",
        }}
      >
        <div className="sla_icon">
          <img src={OnTrackDashboard} alt="on_track_sla_icon" />
        </div>
      </div>
    );
  else if (cardType === "Breached")
    return (
      <div
        className="sla_badge"
        style={{
          background: "var(--Base-Red-4, #FF5546)",
        }}
      >
        <div className="sla_icon">
          <img src={BreachedDashboard} alt="breached_sla_icon" />
        </div>
      </div>
    );
  else if (cardType === "At Risk")
    return (
      <div
        className="sla_badge"
        style={{
          background: "var(--Base-Amber-5, #FFAB00)",
        }}
      >
        <div className="sla_icon">
          <img src={AtRiskDashboard} alt="at_risk_sla_icon" />
        </div>
      </div>
    );
  else if (cardType === "Paused")
    return (
      <div
        className="sla_badge"
        style={{
          background: "#676B7E",
        }}
      >
        <div className="sla_icon">
          <img src={PausedDashboard} alt="paused_sla_icon" />
        </div>
      </div>
    );
  else if (cardType === "Achieved")
    return (
      <div
        className="sla_badge"
        style={{
          background: "#00C853",
        }}
      >
        <div className="sla_icon">
          <img src={AchievedDashboard} alt="paused_sla_icon" />
        </div>
      </div>
    );
};

const getCardClassName = (cardIndex, cardName) => {
  if (
    (cardIndex === 3 && cardName === "Paused") ||
    (cardIndex === 1 && cardName === "Breached") ||
    cardName === "First response SLA"
  )
    return "card no_right_border_card";
  else return "card";
};

const getCurrentProgressBgColor = (cardInfo) => {
  if (cardInfo.name === "Breached") return "var(--Base-Red-5, #D50000)";
  else if (cardInfo.name === "On Track" || cardInfo.name === "Achieved")
    return "var(--Base-Green-5, #00C853)";
  else if (cardInfo.name === "At Risk") return "var(--Base-Amber-5, #FFAB00)";
  else if (cardInfo.name === "Paused")
    return "var(--Base-Blue-grey-9, #676B7E)";
  else if (cardInfo.name === "First response SLA") return "#B388FF";
};

const getParentProgressBgColor = (cardInfo) => {
  if (cardInfo.name === "Breached") return "var(--Base-Red-1, #FEE5E2)";
  else if (cardInfo.name === "On Track" || cardInfo.name === "Achieved")
    return "var(--Base-Green-1, #E2FCEA)";
  else if (cardInfo.name === "At Risk") return "var(--Base-Amber-1, #FFF9E1)";
  else if (cardInfo.name === "Paused") return "#e1e4f0";
  else if (cardInfo.name === "First response SLA") return "#FBF8FF";
};

const removeParamFromURL = (url, param) => {
  const regex = new RegExp(`[?&]${param}=([^$#]+)(&|$)`);
  const newUrl = url.replace(regex, (match, value, separator) =>
    separator === "&" ? separator : ""
  );
  return newUrl;
};

export const Card = ({
  cardIndex,
  cardInfo,
  parameterString,
  totalCount,
  shouldClickBeDisabled,
  closedOrOpen,
}) => {
  const generateTicketsCardHeading = (cardType) => {
    return (
      <div className="card_sla_title_text">
        {getTicketsHeadingContent(cardType)}
      </div>
    );
  };

  const handleCardClick = (e, slaStatusType) => {
    if (shouldClickBeDisabled) return;
    let currentParamString = parameterString;

    if (currentParamString.length === 0) {
      window.location = `/tickets/?sla_status=${slaStatusType}`;
    } else if (
      currentParamString.includes("completed_date_after") &&
      closedOrOpen === "open"
    ) {
      const pstr = removeParamFromURL(
        currentParamString,
        "completed_date_after"
      );
      currentParamString = pstr;
    } else if (
      currentParamString.includes("completed_date_before") &&
      closedOrOpen === "open"
    ) {
      const pstr = removeParamFromURL(
        currentParamString,
        "completed_date_before"
      );
      currentParamString = pstr;
    }
    if (closedOrOpen === "closed") {
      currentParamString = currentParamString + "&ticket_type=closed";
    } else {
      currentParamString = currentParamString + "&ticket_type=open";
    }

    window.location = `/tickets/?sla_status=${slaStatusType}${currentParamString}`;
  };

  return (
    <div
      className={getCardClassName(cardIndex, cardInfo.name)}
      data-testid={`${cardInfo.name}-card`}
      onClick={(e) => handleCardClick(e, cardInfo.name)}
    >
      <div className="card_title_row">
        <div className="card_title_container">
          {generateTicketsCardHeading(cardInfo.name)}
          {generateTicketSlaStatusBadge(cardInfo.name)}
        </div>
        <Tooltip
          title={generateToolTipTitle(cardInfo.name)}
          placement="bottom"
          arrow
          sx={{
            "& .MuiTooltip-tooltip": {
              marginTop: "0px !important",
              borderRadius: "0.1875rem",
              background: "var(--Base-Blue-grey-10, #3C3E49)",
            },
          }}
        >
          <img
            src={InformationCircle}
            alt="information_cirle"
            data-testid="information_tooltip_circle"
          />
        </Tooltip>
      </div>
      <div className="card_progress_row">
        <div className="sla_ticket_count">{cardInfo.count}</div>
        <LinearProgressDeterminate
          progressBarBackground={getParentProgressBgColor(cardInfo)}
          progressBarFill={getCurrentProgressBgColor(cardInfo)}
          progressValue={cardInfo.percent}
        />
        <div className="count_out_of_total">
          <div className="count_percentage_value">{`${cardInfo.percent}%`}</div>
          <div className="count_value_total">{`${cardInfo.count}/${totalCount}`}</div>
        </div>
      </div>
    </div>
  );
};
