import * as React from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import {
  iconsSuperUser,
  iconsGuardianUser,
  iconsNonGuardianrUser,
} from "../../utils/icons";
import Badge from "@mui/material/Badge";
import {
  getTicketsLink,
  isGuardianUser,
  isManagerOrSuperUser,
} from "../../utils/common_functions";

export const CollapsibleSideDrawer = (props) => {
  const iconsList = () => {
    if (isManagerOrSuperUser()) return iconsSuperUser;
    else if (isGuardianUser()) return iconsGuardianUser;
    else return iconsNonGuardianrUser;
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      bottom: 18,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      color: "white",
      backgroundColor: "#6200ea",
    },
  }));
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#651FFF",
      color: "white",
      boxShadow: theme.shadows[1],
      fontSize: 13,
      fontWeight: "bold",
    },
  }));

  const CheckSelected = (link) => {
    return document.location.pathname.includes(link);
  };

  return (
    <List style={props.open ? { paddingleft: "0.5em" } : {}}>
      {iconsList().map((text, index) => (
        <LightTooltip
          key={index}
          title={props.open ? "" : text.icon_name}
          placement="right"
          TransitionComponent={Zoom}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -18],
                  },
                },
              ],
            },
          }}
        >
          <ListItem
            key={index}
            disablePadding
            sx={{ display: "block" }}
            style={{ paddingTop: "0.5em" }}
          >
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: props.open ? "left" : "center",
                px: 2.5,
              }}
              href={getTicketsLink(text.link)}
              selected={CheckSelected(getTicketsLink(text.link))}
              style={{
                color: CheckSelected(getTicketsLink(text.link))
                  ? "#651FFF"
                  : "#3C3E49",
                backgroundColor: CheckSelected(getTicketsLink(text.link))
                  ? "#F3EBFF"
                  : "",
                borderRadius: "10px",
                width: props.open ? "90%" : "70%",
                marginLeft: "0.5em",
                height: "0.5em",
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: "center",
                }}
                style={
                  CheckSelected(getTicketsLink(text.link))
                    ? {
                        color: "#651FFF",
                        marginLeft: props.open ? "-1.5em" : "auto",
                      }
                    : { marginLeft: props.open ? "-1.5em" : "auto" }
                }
              >
                {text.icon_name === "Notifications" ? (
                  <StyledBadge
                    badgeContent={0}
                    color="primary"
                    max={99}
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    size="small"
                  >
                    <text.icon style={{ height: "2rem", width: "1.5rem" }} />
                  </StyledBadge>
                ) : (
                  <text.icon style={{ height: "2rem", width: "1.5rem" }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    style={{
                      fontSize: "0.9em",
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: CheckSelected(getTicketsLink(text.link))
                        ? "bold"
                        : 400,
                      lineHeight: "1.25rem",
                    }}
                  >
                    {text.icon_name}
                  </Typography>
                }
                sx={{ opacity: props.open ? 1 : 0 }}
                style={{ marginLeft: props.open ? "-0.5em" : 0 }}
              ></ListItemText>
            </ListItemButton>
          </ListItem>
        </LightTooltip>
      ))}
    </List>
  );
};
