import * as React from "react";
import AddUserPage from "../../components/AddUserPage";
import { ToastContainer } from "react-toastify";
import { successNotify, errorNotifyList } from "../../components/ToastComponent";

const AddUser = () => {
  const location = window.location.search;
  const receivedValue = location.split("=")[1];
  let page = "";
  if (receivedValue !== "new-user") {
    page = "Edit user-" + receivedValue;
  } else {
    page = "Add guardian user";
  }

  return (
    <>
      <AddUserPage
        page={page}
        errorNotifyList={errorNotifyList}
        successNotify={successNotify}
      />
      <ToastContainer autoClose={2000} />
    </>
  );
}
export default AddUser;
