export const defaultUserSelectedFilter ={
    assignees: [],
    status: [],
    teams: [],
    sub_teams: [],
    dashboardDate: {
      ["Today"]: {
        From: "",
        To: "",
      },
      ["Yesterday"]: {
        From: "",
        To: "",
      },
      ["Last 7 days"]: {
        From: "",
        To: "",
      },
      ["Last 14 days"]: {
        From: "",
        To: "",
      },
      ["Last 30 days"]: {
        From: "",
        To: "",
      },
      ["Custom date"]: {
        From: "",
        To: "",
      },
    },
    sla_status: [],
  };

const getInitialDashboardDateChecked = (item) => {
  return item==="Last 7 days";
}

const dashboardDateOptions = ["Today","Yesterday","Last 7 days","Last 14 days","Last 30 days","Custom date"];

const getSuboptionsForDate = (dashboardDateOptions) => {
  return dashboardDateOptions.map((item,index)=>{
    return {
      type: item,
      id: item,
      checked: getInitialDashboardDateChecked(item),
      parent: "dashboardDate",
      suboptions: [
        {
          type: "From",
          id: "From",
          checked: false,
          parent: item,
        },
        {
          type: "To",
          id: "To",
          checked: false,
          parent: item,
        },
      ],
      showsuboptions: false,
    }
  })
}
export const defaultDateFilterForDashboard = [
  {
    type: "dashboardDate",
    checked: true,
    isHighlighted: false,
    suboptions: getSuboptionsForDate(dashboardDateOptions),
    showsuboptions: false,
  }
];
