import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { defaultUserSelectedFilter } from "../pages/Dashboard/data";

export const clearAllSelectedFilters = (
  setUserSelectedFilter,
  setFilterOptions,
  filterOptions,
  removeCheckFromAllSuboptions
) => {
  setFilterOptions(
    filterOptions.map((filterOption) => {
      if (filterOption.type === "date") {
        return {
          ...filterOption,
          checked: false,
          suboptions: filterOption.suboptions.map((item) => {
            return {
              ...item,
              checked: false,
            };
          }),
        };
      } else {
        return {
          ...filterOption,
          checked: false,
          suboptions: removeCheckFromAllSuboptions(
            filterOption.suboptions,
            filterOption.type
          ),
        };
      }
    })
  );

  setUserSelectedFilter({
    assignees: [],
    status: [],
    sla_status: [],
    team: [],
    sub_team: [],
    date: {
      ["Date created"]: {
        From: "",
        To: "",
      },
      ["Date due"]: {
        From: "",
        To: "",
      },
    },
    closedDate: {
      From: "",
      To: "",
    },
  });

  window.history.replaceState(null, "", window.location.pathname);
};

const updateUrlParams = (selectedType) => {
  if (selectedType === "sla status") selectedType = "sla_status";
  else if (
    selectedType === "teams" &&
    window.location.pathname.includes("tickets")
  )
    selectedType = "team";
  else if (selectedType === "sub teams") selectedType = "sub_teams";
  const url = new URL(window.location);

  const params = new URLSearchParams(url.search);

  const currentTypes = params.getAll("ticket_type");

  if (selectedType === "open tickets") {
    const newTypes = currentTypes.filter((type) => type !== "open");
    params.delete("ticket_type");
    newTypes.forEach((type) => params.append("ticket_type", type));
  }

  if (selectedType === "closed tickets") {
    const newTypes = currentTypes.filter((type) => type !== "closed");
    params.delete("ticket_type");
    newTypes.forEach((type) => params.append("ticket_type", type));
  }

  params.delete(selectedType);
  const newUrl = params.toString() ? `${url.pathname}?${params}` : url.pathname;

  window.history.replaceState({}, "", newUrl);
};

export const findValueName = (valueIndex, itemKey, allFilters) => {
  const updatedItemKey =
    itemKey === "sub_team" || itemKey === "team" ? `${itemKey}s` : itemKey;
  return allFilters[updatedItemKey].filter((item) => item.id == valueIndex)[0]
    .name;
};

export const removeCheckFromAllSuboptions = (suboptions, filterType) => {
  return suboptions.map((subotpn) => {
    return {
      ...subotpn,
      checked: false,
    };
  });
};

export const implementSetUserSelectedFilter = (
  userSelectedFilter,
  selectedSubType,
  selectedType
) => {
  return {
    ...userSelectedFilter,
    [selectedType]: {
      ...userSelectedFilter[selectedType],
      [selectedSubType]: {
        From: "",
        To: "",
      },
    },
  };
};

export const FilterSubOptions = ({
  handleOnDateHover,
  handleChildClick,
  filter,
  sub,
  childIndex,
  index,
  findChildFilterSubElements,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter,
}) => {
  const mouseOverCondition = ["Date due", "Date created"].includes(sub.type);
  return (
    <div
      onMouseOver={(e) => {
        mouseOverCondition && handleOnDateHover(e, sub.type);
      }}
      className="sub_filter_checkbox"
      style={{ paddingLeft: "0.8rem" }}
      onClick={(e) => {
        (mouseOverCondition || filter.type !== "closedDate") &&
          handleChildClick(
            e,
            filter.type,
            sub.id,
            index,
            childIndex,
            sub,
            sub.type
          );
      }}
    >
      {findChildFilterSubElements(
        filter.type,
        sub,
        filterOptions,
        setFilterOptions,
        userSelectedFilter,
        setUserSelectedFilter
      )}
    </div>
  );
};

export const getUpdatedSuboptionsAfterRemoving = (
  suboptions,
  selectedSubType
) => {
  return suboptions.map((suboption) => {
    if (suboption.type === selectedSubType) {
      return {
        ...suboption,
        checked: false,
      };
    } else return suboption;
  });
};

export const implementSetFilterOptions = (
  filterOptions,
  key,
  selectedSubType
) => {
  const updatedFilterOptions = filterOptions.map((filterOption) => {
    if (filterOption.type === key) {
      return {
        ...filterOption,
        suboptions: getUpdatedSuboptionsAfterRemoving(
          filterOption.suboptions,
          selectedSubType
        ),
        checked: false,
      };
    } else return filterOption;
  });
  return updatedFilterOptions;
};

export const removeSelectedFilterForDate = (
  setUserSelectedFilter,
  selectedSubType,
  filterOptions,
  setFilterOptions,
  selectedType
) => {
  setUserSelectedFilter((userSelectedFilter) => {
    return implementSetUserSelectedFilter(
      userSelectedFilter,
      selectedSubType,
      selectedType
    );
  });

  setFilterOptions((filterOptions) => {
    return implementSetFilterOptions(
      filterOptions,
      selectedType,
      selectedSubType
    );
  });

  if (selectedSubType === "Date created") {
    updateUrlParams("created_date_after");
    updateUrlParams("created_date_before");
  }

  if (selectedSubType === "Date due") {
    updateUrlParams("due_date_after");
    updateUrlParams("due_date_before");
  }
};

export const removeTicketTypeUserSelectedFilter = (
  userSelectedFilter,
  ticketType
) => {
  const tempUserSelectedFilter = { ...userSelectedFilter };
  tempUserSelectedFilter[ticketType] = false;

  return tempUserSelectedFilter;
};

export const removeSelectedFilterForTicketType = (
  userSelectedFilter,
  setUserSelectedFilter,
  filterOptions,
  setFilterOptions,
  ticketType
) => {
  const tempUserSelectedFilter = { ...userSelectedFilter };

  tempUserSelectedFilter[ticketType] = false;

  setFilterOptions(
    filterOptions.map((filterOption) => {
      if (filterOption.type === ticketType) {
        return {
          ...filterOption,
          checked: false,
        };
      } else return filterOption;
    })
  );

  setUserSelectedFilter((userSelectedFilter) => {
    return removeTicketTypeUserSelectedFilter(userSelectedFilter, ticketType);
  });

  updateUrlParams(ticketType);
};

export const removeSelectedUserFilter = (selectedType, userSelectedFilter) => {
  const selectedTypeValue =
    selectedType === "sla status" ? "sla_status" : selectedType;
  const selectedTypeValueForSubTeam =
    selectedType === "sub teams" || selectedType === "sub_team"
      ? "sub_teams"
      : selectedType;
  const selectedTypeForTeam =
    selectedType === "teams" || selectedType === "team" ? "team" : selectedType;
  return {
    ...userSelectedFilter,
    [selectedTypeValue]: [],
    [selectedTypeValueForSubTeam]: [],
    [selectedTypeForTeam]: [],
  };
};

export const updateFilterOptionsRemoveFilter = (filterOption, selectedType) => {
  const selectedTypeValue =
    selectedType === "sla status" ? "sla_status" : selectedType;
  const selectedTypeValueForSubTeam =
    selectedType === "sub teams" || selectedType === "sub_team"
      ? "sub_teams"
      : selectedType;
  const selectedTypeForTeam =
    selectedType === "teams" || selectedType === "team" ? "team" : selectedType;

  if (
    filterOption.type === selectedTypeValue ||
    filterOption.type === selectedTypeValueForSubTeam ||
    filterOption.type === selectedTypeForTeam
  ) {
    return {
      ...filterOption,
      checked: false,
      suboptions: removeCheckFromAllSuboptions(
        filterOption.suboptions,
        filterOption.type
      ),
    };
  } else return filterOption;
};

export const removeClosedDateFilter = (
  setFilterOptions,
  filterOptions,
  setUserSelectedFilter
) => {
  setFilterOptions(
    filterOptions.map((filterOption) => {
      if (filterOption.type === "closedDate") {
        return {
          ...filterOption,
          checked: false,
        };
      } else return filterOption;
    })
  );

  setUserSelectedFilter((userSelectedFilter) => {
    return {
      ...userSelectedFilter,
      closedDate: {
        From: "",
        To: "",
      },
    };
  });

  updateUrlParams("completed_date_after");
  updateUrlParams("completed_date_before");
};

export const removeSelectedFilter = (
  selectedType,
  selectedSubType,
  userSelectedFilter,
  setUserSelectedFilter,
  filterOptions,
  setFilterOptions
) => {
  if (selectedType === "date" || selectedType === "dashboardDate") {
    removeSelectedFilterForDate(
      setUserSelectedFilter,
      selectedSubType,
      filterOptions,
      setFilterOptions,
      selectedType
    );

    return;
  } else if (
    selectedType === "closed tickets" ||
    selectedType === "open tickets"
  ) {
    const ticketType =
      selectedType === "closed tickets" ? "closed tickets" : "open tickets";
    removeSelectedFilterForTicketType(
      userSelectedFilter,
      setUserSelectedFilter,
      filterOptions,
      setFilterOptions,
      ticketType
    );

    return;
  }

  setUserSelectedFilter(
    removeSelectedUserFilter(selectedType, userSelectedFilter)
  );

  setFilterOptions(
    filterOptions.map((filterOption) => {
      return updateFilterOptionsRemoveFilter(filterOption, selectedType);
    })
  );

  updateUrlParams(selectedType);
};

export const capitalizeFirstLetter = (str) => {
  if (str === "sla_status") return "Sla status";
  else if (str === "closedDate") return "Closed date";
  else if (str === "sub_teams") return "Sub Teams";
  else if (str === "team") return "Teams";
  else if (str === "dashboardDate") return "Date";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const valueReturnForDisplaySelectedFilters = (
  valueArr,
  findValueName,
  itemKey,
  allFilters
) => {
  return valueArr.length === 1
    ? findValueName(valueArr[0], itemKey, allFilters)
    : valueArr.length;
};

const getChoosenDateFilter = (userSelectedFilter, filterkey, label) => {
  let from, to;

  if (filterkey === "closedDate") {
    from = userSelectedFilter[filterkey]["From"];
    to = userSelectedFilter[filterkey]["To"];
  } else {
    from = userSelectedFilter[filterkey][label]["From"];
    to = userSelectedFilter[filterkey][label]["To"];
  }

  const dateFrom = new Date(from);
  const dateTo = new Date(to);

  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDateFrom = dateFrom.toLocaleDateString("en-GB", options);
  const formattedDateTo = dateTo.toLocaleDateString("en-GB", options);

  return (
    <Card
      variant="outlined"
      sx={{
        position: "absolute",
        zIndex: "modal",
        marginTop: "3rem",
        boxShadow: "2px 4px 16px 3px rgba(0, 0, 0, 0.15)",
        borderRadius: "0.5rem",
      }}
    >
      <CardContent>
        <Typography>
          <span style={{ fontWeight: "bold" }}>From:</span> {formattedDateFrom}
        </Typography>
        {to && (
          <Typography>
            <span style={{ fontWeight: "bold" }}>To:</span> {formattedDateTo}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export const GetDateChip = ({
  filterkey,
  label,
  userSelectedFilter,
  onDelete,
  deleteIcon,
}) => {
  const [isCardVisible, setIsCardVisible] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: "0.5rem",
      }}
    >
      <Chip
        style={{
          color: "var(--Base-Purple-5, #651FFF)",
          fontFamily: "Rubik, sans-serif",
          fontSize: "0.875rem",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "1.25rem",
          letterSpacing: "0.00875rem",
          padding: "0.15rem 0.2rem",
          gap: "0.25rem",
          borderRadius: "0.25rem",
          border: "0.8px solid var(--Base-Blue-grey-7, #9B9FB1)",
          height: "2.4rem",
          background: "white",
        }}
        label={label}
        onDelete={onDelete}
        deleteIcon={deleteIcon}
        onMouseEnter={() => setIsCardVisible(true)}
        onMouseLeave={() => setIsCardVisible(false)}
        sx={{
          [`& .MuiChip-deleteIcon`]: {
            color: "var(--Base-Purple-5, #651FFF)",
            fontFamily: "Rubik, sans-serif",
            fontSize: "0.875rem",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "1.25rem",
            letterSpacing: "0.00875rem",
          },
        }}
      />
      {isCardVisible &&
        getChoosenDateFilter(userSelectedFilter, filterkey, label)}
    </div>
  );
};

const TicketChip = ({ label, onDelete }) => {
  return (
    <Chip
      data-test-id={`${label.replace(/\s+/g, "-").toLowerCase()}-chip`}
      style={{
        color: "var(--Base-Purple-5, #651FFF)",
        fontFamily: "Rubik, sans-serif",
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "1.25rem",
        letterSpacing: "0.00875rem",
        padding: "0.15rem 0.2rem",
        gap: "0.25rem",
        borderRadius: "0.25rem",
        border: "0.8px solid var(--Base-Blue-grey-7, #9B9FB1)",
        height: "2.4rem",
        background: "white",
        marginRight: label === "Closed Tickets" ? "0.5rem" : "0",
      }}
      label={label}
      onDelete={onDelete}
      deleteIcon={<ClearIcon />}
      sx={{
        [`& .MuiChip-deleteIcon`]: {
          color: "var(--Base-Purple-5, #651FFF)",
          fontFamily: "Rubik, sans-serif",
          fontSize: "0.875rem",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "1.25rem",
          letterSpacing: "0.00875rem",
        },
      }}
    />
  );
};

export const checkkey = (key) => {
  if (key === "sub teams" || key === "sub_team") return "sub_teams";
  else if (key === "sla status") return "sla_status";
  return key;
};

const filterObject = (key, selectedFilters, selectedIds) => {
  return selectedFilters.filter((item) => selectedIds.includes(item.id));
};

export const convertTeamIDsToString = (userSelectedFilter) => {
  return Object.fromEntries(
    Object.entries(userSelectedFilter).map(([key, value]) => {
      if (key === "teams") return [key, value.map((id) => id.toString())];
      else return [key, value];
    })
  );
};

export const getChoosenFilter = (userSelectedFilter, filterkey, allFilters) => {
  const key = filterkey.toLowerCase();
  const checkedKey = checkkey(key);

  let updatedUserSelectedFilter = {
    ...convertTeamIDsToString(userSelectedFilter),
  };

  if (window.location.href.match("/ticket")) {
    updatedUserSelectedFilter = {
      ...userSelectedFilter,
      teams: userSelectedFilter["team"] ?? [],
      sub_teams: userSelectedFilter["sub_team"] ?? [],
    };
  }

  const selectedIds = updatedUserSelectedFilter[checkedKey];

  const selectedFilters = allFilters[checkedKey];

  const filtered = filterObject(key, selectedFilters, selectedIds);

  return (
    <Card
      variant="outlined"
      sx={{
        position: "absolute",
        zIndex: "modal",
        marginTop: "3rem",
        boxShadow: "2px 4px 16px 3px rgba(0, 0, 0, 0.15)",
        borderRadius: "0.5rem",
      }}
    >
      <CardContent
        sx={{
          paddingBottom: "16px !important",
        }}
      >
        {filtered.map((item) => (
          <Typography key={item.name} sx={{}}>
            {subFilterNameStyle(item.name)}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
};

export const GetChip = ({
  filterkey,
  value,
  allFilters,
  userSelectedFilter,
  setUserSelectedFilter,
  filterOptions,
  setFilterOptions,
}) => {
  const [isCardVisible, setIsCardVisible] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: "0.5rem",
      }}
    >
      <Chip
        key={filterkey}
        style={{
          color: "var(--Base-Purple-5, #651FFF)",
          fontFamily: "Rubik, sans-serif",
          fontSize: "0.875rem",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "1.25rem",
          letterSpacing: "0.00875rem",
          padding: "0.15rem 0.2rem",
          gap: "0.25rem",
          borderRadius: "0.25rem",
          border: "0.8px solid var(--Base-Blue-grey-7, #9B9FB1)",
          height: "2.4rem",
          background: "white",
        }}
        label={`${filterkey} (${value})`}
        onDelete={(e) =>
          removeSelectedFilter(
            filterkey.toLowerCase(),
            null,
            userSelectedFilter,
            setUserSelectedFilter,
            filterOptions,
            setFilterOptions
          )
        }
        onMouseEnter={() => setIsCardVisible(true)}
        onMouseLeave={() => setIsCardVisible(false)}
        deleteIcon={<ClearIcon />}
        sx={{
          [`& .MuiChip-deleteIcon`]: {
            color: "var(--Base-Purple-5, #651FFF)",
            fontFamily: "Rubik, sans-serif",
            fontSize: "0.875rem",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "1.25rem",
            letterSpacing: "0.00875rem",
          },
        }}
      />
      {isCardVisible &&
        getChoosenFilter(userSelectedFilter, filterkey, allFilters)}
    </div>
  );
};

export const getOverallTicketType = (userSelectedFilter, allFilters) => {
  const ticketTypes = new Set();

  const updatedUserSelectedFilter = {
    ...userSelectedFilter,
    teams: userSelectedFilter["team"],
    sub_teams: userSelectedFilter["sub_team"],
  };

  Object.keys(updatedUserSelectedFilter).forEach((itemKey) => {
    if (updatedUserSelectedFilter[itemKey].length > 0) {
      const filterData = getFilterData(
        updatedUserSelectedFilter,
        itemKey,
        allFilters,
        "ticket_type"
      );

      filterData.forEach((data) => {
        if (data.ticket_type) {
          ticketTypes.add(data.ticket_type);
        }
      });
    }
  });

  let overallTicketType = "Open";

  if (
    updatedUserSelectedFilter["closed tickets"] &&
    updatedUserSelectedFilter["open tickets"]
  ) {
    overallTicketType = "All";
  } else if (updatedUserSelectedFilter["closed tickets"]) {
    overallTicketType = "Closed";
  } else if (
    ticketTypes.has("all") &&
    !updatedUserSelectedFilter["open tickets"]
  ) {
    overallTicketType = "All";
  } else if (ticketTypes.size === 1 && ticketTypes.has("closed")) {
    overallTicketType = "Closed";
  } else if (ticketTypes.has("closed") && ticketTypes.has("open")) {
    overallTicketType = "All";
  }

  return <>{`${overallTicketType} Tickets`}</>;
};

const renderDateChips = (
  userSelectedFilter,
  dateType,
  allFilters,
  setUserSelectedFilter,
  filterOptions,
  setFilterOptions
) => {
  const displayFromDate =
    userSelectedFilter["date"][dateType]["From"].length > 0;
  const displayToDate = userSelectedFilter["date"][dateType]["To"].length > 0;

  return (
    <>
      {displayFromDate || displayToDate ? (
        <GetDateChip
          filterkey="date"
          label={dateType}
          allFilters={allFilters}
          userSelectedFilter={userSelectedFilter}
          onDelete={() => {
            removeSelectedFilter(
              "date",
              dateType,
              userSelectedFilter,
              setUserSelectedFilter,
              filterOptions,
              setFilterOptions
            );
          }}
          deleteIcon={<ClearIcon />}
        />
      ) : null}
    </>
  );
};

const renderDashboardDateChips = (
  userSelectedFilter,
  allFilters,
  setUserSelectedFilter,
  filterOptions,
  setFilterOptions
) => {
  const dateTypes = [
    "Custom date",
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 14 days",
    "Last 30 days",
  ];

  return dateTypes.map((dateType) => {
    const displayDate =
      userSelectedFilter["dashboardDate"][dateType]["From"].length > 0 ||
      userSelectedFilter["dashboardDate"][dateType]["To"].length > 0;

    return (
      displayDate && (
        <GetDateChip
          key={dateType}
          filterkey="dashboardDate"
          label={dateType}
          allFilters={allFilters}
          userSelectedFilter={userSelectedFilter}
          setUserSelectedFilter={setUserSelectedFilter}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
      )
    );
  });
};

const renderClosedDateChip = (
  userSelectedFilter,
  allFilters,
  setUserSelectedFilter,
  filterOptions,
  setFilterOptions
) => {
  const closedDateStatus =
    userSelectedFilter["closedDate"]["From"].length > 0 ||
    userSelectedFilter["closedDate"]["To"].length > 0;

  return (
    closedDateStatus && (
      <GetDateChip
        filterkey="closedDate"
        label="Closed Date"
        allFilters={allFilters}
        userSelectedFilter={userSelectedFilter}
        onDelete={() => {
          removeClosedDateFilter(
            setFilterOptions,
            filterOptions,
            setUserSelectedFilter
          );
        }}
        deleteIcon={<ClearIcon />}
      />
    )
  );
};

const renderFilterChip = (
  itemKey,
  userSelectedFilter,
  capitalizeFirstLetter,
  allFilters,
  setUserSelectedFilter,
  filterOptions,
  setFilterOptions
) => {
  const key = capitalizeFirstLetter(itemKey);
  const valueArr = userSelectedFilter[itemKey];
  const value = valueReturnForDisplaySelectedFilters(
    valueArr,
    findValueName,
    itemKey,
    allFilters
  );

  return (
    <GetChip
      key={itemKey}
      filterkey={key}
      value={value}
      allFilters={allFilters}
      userSelectedFilter={userSelectedFilter}
      setUserSelectedFilter={setUserSelectedFilter}
      filterOptions={filterOptions}
      setFilterOptions={setFilterOptions}
    />
  );
};

const renderTicketChip = (
  itemKey,
  label,
  userSelectedFilter,
  setUserSelectedFilter,
  filterOptions,
  setFilterOptions
) =>
  userSelectedFilter[label.toLowerCase().replace(/\s+/g, " ")] && (
    <TicketChip
      key={itemKey}
      label={label}
      onDelete={() =>
        removeSelectedFilter(
          label.toLowerCase().replace(/\s+/g, " "),
          null,
          userSelectedFilter,
          setUserSelectedFilter,
          filterOptions,
          setFilterOptions
        )
      }
    />
  );

export const displaySelectedFilters = (
  userSelectedFilter,
  capitalizeFirstLetter,
  allFilters,
  setUserSelectedFilter,
  filterOptions,
  setFilterOptions
) => {
  const updatedUserSelectedFilter = {
    ...userSelectedFilter,
  };

  return Object.keys(updatedUserSelectedFilter).map((itemKey) => {
    if (
      updatedUserSelectedFilter[itemKey].length > 0 &&
      !["date", "dashboardDate", "ticket_type"].includes(itemKey)
    ) {
      return renderFilterChip(
        itemKey,
        updatedUserSelectedFilter,
        capitalizeFirstLetter,
        allFilters,
        setUserSelectedFilter,
        filterOptions,
        setFilterOptions
      );
    } else {
      switch (itemKey) {
        case "date":
          return (
            <>
              {renderDateChips(
                userSelectedFilter,
                "Date created",
                allFilters,
                setUserSelectedFilter,
                filterOptions,
                setFilterOptions
              )}
              {renderDateChips(
                userSelectedFilter,
                "Date due",
                allFilters,
                setUserSelectedFilter,
                filterOptions,
                setFilterOptions
              )}
            </>
          );
        case "dashboardDate":
          return renderDashboardDateChips(
            userSelectedFilter,
            allFilters,
            setUserSelectedFilter,
            filterOptions,
            setFilterOptions
          );

        case "closedDate":
          return renderClosedDateChip(
            userSelectedFilter,
            allFilters,
            setUserSelectedFilter,
            filterOptions,
            setFilterOptions
          );

        case "closed tickets":
          return renderTicketChip(
            itemKey,
            "Closed Tickets",
            userSelectedFilter,
            setUserSelectedFilter,
            filterOptions,
            setFilterOptions
          );

        case "open tickets":
          return renderTicketChip(
            itemKey,
            "Open Tickets",
            userSelectedFilter,
            setUserSelectedFilter,
            filterOptions,
            setFilterOptions
          );
        default:
          return null;
      }
    }
  });
};

export const formatDateValue = (date) => {
  const selectedDate = new Date(date);
  const fullYear = selectedDate.getFullYear();
  const month = selectedDate.getMonth() + 1;
  const day = selectedDate.getDate();

  const updatedMonth = month < 10 ? `0${month}` : month;
  const updatedDate = day < 10 ? `0${day}` : day;

  return `${fullYear}-${updatedMonth}-${updatedDate}`;
};

export const findUpdatedSuboptionsList = (
  suboptions,
  childFilterIndex,
  parentFilterType
) => {
  let newSuboptions = [];

  newSuboptions = suboptions.map((suboption, index) => {
    if (
      ["assignees", "status", "teams", "sub_teams", "sla_status"].includes(
        parentFilterType
      )
    ) {
      if (index === childFilterIndex) {
        return {
          ...suboption,
          checked: true,
        };
      } else {
        return {
          ...suboption,
          checked: suboption.checked,
        };
      }
    } else {
      return {
        ...suboption,
        checked: index === childFilterIndex,
      };
    }
  });

  return newSuboptions;
};

export const getUpdatedKey = (itemKey) => {
  let udpatedKey = itemKey;
  if (itemKey == "teams") return "team";
  else if (itemKey == "sub_teams") return "sub_team";

  return udpatedKey;
};

export function updateUserSelectedFilter(
  e,
  parentFilterType,
  childFilterId,
  parentFilterIndex,
  childFilterIndex,
  sub,
  childFilterType,
  handleChildFilterUnchecked,
  userSelectedFilter,
  filterOptions,
  setFilterOptions,
  setUserSelectedFilter
) {
  if (sub.checked === true) {
    handleChildFilterUnchecked(
      e,
      parentFilterType,
      sub,
      filterOptions,
      setFilterOptions,
      userSelectedFilter,
      setUserSelectedFilter
    );
    return;
  }

  const updatedParentFiltertype = getUpdatedKey(parentFilterType);
  const newUserSelectedFilter = {
    ...userSelectedFilter,
    [updatedParentFiltertype]: [
      ...userSelectedFilter[updatedParentFiltertype],
      childFilterId,
    ],
  };

  setFilterOptions(
    filterOptions.map((item, index) =>
      index === parentFilterIndex
        ? {
            ...item,
            checked: true,
            isHighlighted: true,
            suboptions: findUpdatedSuboptionsList(
              item.suboptions,
              childFilterIndex,
              parentFilterType
            ),
          }
        : { ...item, isHighlighted: false }
    )
  );

  setUserSelectedFilter(newUserSelectedFilter);

  updateUrlWithFilters(newUserSelectedFilter);
}

const handleArrayValues = (params, key, value) => {
  if (key === "assignees") {
    params.set(key, value.join(","));
  } else {
    value.forEach((v) => {
      params.append(key, v);
    });
  }
};

const handleUrlClosedDate = (params, value) => {
  if (value.From) params.set("completed_date_after", value.From);
  if (value.To) params.set("completed_date_before", value.To);
};

const handleUrlCreatedDate = (params, dateRange) => {
  if (dateRange.From) params.set("created_date_after", dateRange.From);
  if (dateRange.To) params.set("created_date_before", dateRange.To);
};

const handleUrlDueDate = (params, dateRange) => {
  if (dateRange.From) params.set("due_date_after", dateRange.From);
  if (dateRange.To) params.set("due_date_before", dateRange.To);
};

const handleDateRange = (params, key, value, dateKey, dateRange) => {
  if (key === "closedDate") {
    handleUrlClosedDate(params, value);
  } else if (key === "date") {
    if (dateKey === "Date created") {
      handleUrlCreatedDate(params, dateRange);
    } else if (dateKey === "Date due") {
      handleUrlDueDate(params, dateRange);
    }
  }
};

const handleObjectValues = (params, key, value) => {
  Object.keys(value).forEach((dateKey) => {
    const dateRange = value[dateKey];
    handleDateRange(params, key, value, dateKey, dateRange);
  });
};

const handleTicketTypes = (params, isOpenTickets, isClosedTickets) => {
  if (isOpenTickets && isClosedTickets) {
    params.append("ticket_type", "open");
    params.append("ticket_type", "closed");
  } else if (isOpenTickets) {
    params.set("ticket_type", "open");
  } else if (isClosedTickets) {
    params.set("ticket_type", "closed");
  }
};

export const updateUrlWithFilters = (newUserSelectedFilter) => {
  const params = new URLSearchParams();

  Object.keys(newUserSelectedFilter).forEach((key) => {
    const value = newUserSelectedFilter[key];

    if (key === "dashboardDate") return;

    if (Array.isArray(value) && value.length > 0) {
      handleArrayValues(params, key, value);
    } else if (typeof value === "object" && value !== null) {
      handleObjectValues(params, key, value);
    }
  });

  const isOpenTickets = newUserSelectedFilter["open tickets"];
  const isClosedTickets = newUserSelectedFilter["closed tickets"];

  handleTicketTypes(params, isOpenTickets, isClosedTickets);

  const paramString = params.toString();
  const newUrl = paramString
    ? `${window.location.pathname}?${paramString}`
    : window.location.pathname;

  window.history.replaceState(null, "", newUrl);
};

export const findCheckedSubElementsCount = (suboptions, excludedType) => {
  return (
    suboptions.filter((item) => item.type !== excludedType && item.checked)
      .length > 0
  );
};

export const uncheckUnselectedChildFilter = (suboptions, childFilterType) => {
  return suboptions.map((suboption) => {
    if (suboption.type === childFilterType) {
      return {
        ...suboption,
        checked: false,
      };
    } else {
      return {
        ...suboption,
      };
    }
  });
};

export const removeUncheckedChildFilter = (selectedArr, childFilterId) => {
  return selectedArr.filter((item) => item != childFilterId);
};

export const handleChildFilterUnchecked = (
  e,
  parentFilterType,
  childFilter,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  if (!childFilter.checked) {
    e.stopPropagation();
    return;
  }

  setFilterOptions(
    updateFilterOptions(filterOptions, parentFilterType, childFilter)
  );

  setUserSelectedFilter((userSelectedFilter) => {
    const updatedParentFilterType = convertParentFilterType(parentFilterType);
    return {
      ...userSelectedFilter,
      [updatedParentFilterType]: removeUncheckedChildFilter(
        userSelectedFilter[updatedParentFilterType],
        childFilter.id
      ),
    };
  });

  e.stopPropagation();
};

const updateFilterOptions = (filterOptions, parentFilterType, childFilter) => {
  return filterOptions.map((filterOption) => {
    if (filterOption.type === parentFilterType) {
      return {
        ...filterOption,
        suboptions: uncheckUnselectedChildFilter(
          filterOption.suboptions,
          childFilter.type
        ),
        checked: findCheckedSubElementsCount(
          filterOption.suboptions,
          childFilter.type
        ),
      };
    } else {
      return {
        ...filterOption,
      };
    }
  });
};

const convertParentFilterType = (parentFilterType) => {
  const mapping = {
    teams: "team",
    sub_teams: "sub_team",
  };

  return window.location.href.includes("/ticket")
    ? mapping[parentFilterType] || parentFilterType
    : parentFilterType;
};

export const removeSelectedSubOptions = (suboptions) => {
  return suboptions.map((suboption) => {
    return {
      ...suboption,
      checked: false,
    };
  });
};

export const filterUncheckedForDateCt = (
  filter,
  setFilterOptions,
  filterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  if (
    filter.checked &&
    !["date", "dashboardDate"].includes(filter.type) &&
    filter.type !== "closed tickets" &&
    filter.type !== "open tickets"
  ) {
    setFilterOptions(
      filterOptions.map((filterOption) => {
        if (filterOption.type === filter.type) {
          return {
            ...filterOption,
            checked: false,
            suboptions: removeSelectedSubOptions(filterOption.suboptions),
          };
        } else return filterOption;
      })
    );

    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...userSelectedFilter,
        [filter.type]: [],
      };
    });
  }
};

export const filterUncheckedForDate = (
  filterType,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  if (filterType === "date") {
    setFilterOptions((filterOptions) => {
      return filterOptions.map((filterOption) => {
        if (filterOption.type === "date") {
          return {
            ...filterOption,
            checked: false,
          };
        } else return filterOption;
      });
    });

    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...userSelectedFilter,
        date: {
          ["Date created"]: {
            From: "",
            To: "",
          },
          ["Date due"]: {
            From: "",
            To: "",
          },
        },
      };
    });
  } else if (filterType === "dashboardDate") {
    setFilterOptions((filterOptions) => {
      return filterOptions.map((filterOption) => {
        if (filterOption.type === "dashboardDate") {
          return {
            ...filterOption,
            checked: false,
            suboptions: removeCheckFromAllSuboptions(filterOption.suboptions),
          };
        } else return filterOption;
      });
    });

    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...userSelectedFilter,
        dashboardDate: {
          ["Today"]: {
            From: "",
            To: "",
          },
          ["Yesterday"]: {
            From: "",
            To: "",
          },
          ["Last 7 days"]: {
            From: "",
            To: "",
          },
          ["Last 14 days"]: {
            From: "",
            To: "",
          },
          ["Last 30 days"]: {
            From: "",
            To: "",
          },
          ["Custom date"]: {
            From: "",
            To: "",
          },
        },
      };
    });
  }
};

export const handleParentFilterUnchecked = (
  e,
  filter,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  const updateFilterOptions = (type, updateFunction) => {
    setFilterOptions(
      filterOptions.map((filterOption) => {
        if (filterOption.type === type) {
          return updateFunction(filterOption);
        } else {
          return filterOption;
        }
      })
    );
  };

  const updateSelectedFilter = (type, updateFunction) => {
    setUserSelectedFilter((prevUserSelectedFilter) => ({
      ...prevUserSelectedFilter,
      ...updateFunction(prevUserSelectedFilter[type]),
    }));
  };

  const handleDateFilterUnchecked = () => {
    updateFilterOptions(filter.type, (filterOption) => ({
      ...filterOption,
      checked: false,
    }));
  
    setUserSelectedFilter((prevUserSelectedFilter) => {
      return {
        ...prevUserSelectedFilter,
        date: {
          ["Date created"]: {
            From: "",
            To: "",
          },
          ["Date due"]: {
            From: "",
            To: "",
          },
        },
      };
    });
  };

  const handleTicketsFilterUnchecked = (ticketType) => {
    let tempUserSelectedFilter = { ...userSelectedFilter };
    tempUserSelectedFilter[ticketType] = false;

    updateFilterOptions(filter.type, (filterOption) => ({
      ...filterOption,
      checked: false,
    }));
    setUserSelectedFilter(tempUserSelectedFilter);
  };

  const handleClosedDateFilterUnchecked = () => {
    updateFilterOptions(filter.type, (filterOption) => ({
      ...filterOption,
      checked: false,
      suboptions: filterOption.suboptions.map((suboption) => ({
        ...suboption,
        checked: false,
        showsuboptions: false,
      })),
    }));

    setUserSelectedFilter((prevUserSelectedFilter) => ({
      ...prevUserSelectedFilter,
      closedDate: { From: "", To: "" },
    }));
  };

  switch (filter.type) {
    case "date":
      handleDateFilterUnchecked();
      break;
    case "closed tickets":
      handleTicketsFilterUnchecked("closed tickets");
      break;
    case "open tickets":
      handleTicketsFilterUnchecked("open tickets");
      break;
    case "closedDate":
      handleClosedDateFilterUnchecked();
      break;
    default:
      console.log("HandleParentFalse");
  }

  e.stopPropagation();
};

export const handleParentClick = (
  e,
  index,
  filter,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  if (filter.type === "closed tickets") {
    if (filter.checked === true) {
      handleParentFilterUnchecked(
        e,
        filter,
        filterOptions,
        setFilterOptions,
        userSelectedFilter,
        setUserSelectedFilter
      );
      return;
    }

    const updatedFilterOptions = filterOptions.map((filterOption) => {
      if (filterOption.type === "closed tickets") {
        return {
          ...filterOption,
          checked: true,
          isHighlighted: true,
          showsuboptions: false,
        };
      }
      return filterOption;
    });

    setFilterOptions(updatedFilterOptions);
    setUserSelectedFilter({
      ...userSelectedFilter,
      ["closed tickets"]: true,
    });

    return;
  }
  if (filter.type === "open tickets") {
    if (filter.checked === true) {
      handleParentFilterUnchecked(
        e,
        filter,
        filterOptions,
        setFilterOptions,
        userSelectedFilter,
        setUserSelectedFilter
      );
      return;
    }

    const updatedFilterOptions = filterOptions.map((filterOption) => {
      if (filterOption.type === "open tickets") {
        return {
          ...filterOption,
          checked: true,
          isHighlighted: true,
          showsuboptions: false,
        };
      }
      return filterOption;
    });

    setFilterOptions(updatedFilterOptions);
    setUserSelectedFilter({
      ...userSelectedFilter,
      ["open tickets"]: true,
    });

    return;
  }

  const updatedFilterOptions = filterOptions.map((filterOption, i) => ({
    ...filterOption,
    isHighlighted: i == index,
    showsuboptions: i == index,
  }));

  setFilterOptions(updatedFilterOptions);
};

const generateCreatedDateString = (userSelectedFilter, item) => {
  return Object.keys(userSelectedFilter[item]["Date created"])
    .map((itemKey) => {
      if (
        itemKey === "From" &&
        userSelectedFilter[item]["Date created"]["From"].length > 0
      )
        return `&created_date_after=${userSelectedFilter[item]["Date created"]["From"]}`;
      else if (
        itemKey === "From" &&
        userSelectedFilter[item]["Date created"]["From"].length === 0
      )
        return "";
      else if (
        itemKey === "To" &&
        userSelectedFilter[item]["Date created"]["To"].length > 0
      )
        return `&created_date_before=${userSelectedFilter[item]["Date created"]["To"]}`;
      else if (
        itemKey === "To" &&
        userSelectedFilter[item]["Date created"]["To"].length === 0
      )
        return "";
    })
    .join("");
};

const generateDueDateString = (userSelectedFilter, item) => {
  return Object.keys(userSelectedFilter[item]["Date due"])
    .map((itemKey) => {
      if (
        itemKey === "From" &&
        userSelectedFilter[item]["Date due"]["From"].length > 0
      )
        return `&due_date_after=${userSelectedFilter[item]["Date due"]["From"]}`;
      else if (
        itemKey === "From" &&
        userSelectedFilter[item]["Date due"]["From"].length === 0
      )
        return "";
      else if (
        itemKey === "To" &&
        userSelectedFilter[item]["Date due"]["To"].length > 0
      )
        return `&due_date_before=${userSelectedFilter[item]["Date due"]["To"]}`;
      else if (
        itemKey === "To" &&
        userSelectedFilter[item]["Date due"]["To"].length === 0
      )
        return "";
    })
    .join("");
};

const generateDateParamString = (pstr, closedDate) => {
  if (closedDate.From.length === 0 && closedDate.To.length === 0)
    return `${pstr}`;

  let dateString = "";

  Object.entries(closedDate).map((item) => {
    const [key, value] = item;

    if (value.length > 0) {
      if (key === "From") dateString += `&completed_date_after=${value}`;
      else if (key === "To") dateString += `&completed_date_before=${value}`;
    }
  });

  return `${pstr}${dateString}`;
};

export const getFilterData = (
  userSelectedFilter,
  filterKey,
  allFilters,
  typeKey
) => {
  if (
    userSelectedFilter[filterKey] &&
    userSelectedFilter[filterKey].length > 0
  ) {
    const selectedFilterIds = userSelectedFilter[filterKey];

    const selectedFilters = allFilters[filterKey];

    const temp = {};
    const filterDataList = (selectedFilters || []).filter((item) => {
      temp[item.id] = item.name;
      return selectedFilterIds.includes(item.id);
    });

    if (filterDataList.length > 0) {
      return filterDataList.map((item) => ({
        id: item.id,
        name: item.name,
        ticket_type: item[typeKey],
      }));
    } else {
      console.warn(`No filters selected for ${filterKey}`);
    }
  }

  return [];
};

const generateStatusOrSlaString = (userSelectedFilter, item, allFilters) => {
  if (userSelectedFilter[item].length === 0) {
    return "";
  }
  const filterData = getFilterData(
    userSelectedFilter,
    item,
    allFilters,
    "ticket_type"
  );
  return filterData
    .map(({ id, ticket_type }) => `&${item}=${id}&ticket_type=${ticket_type}`)
    .join("");
};

const generateAssigneesString = (userSelectedFilter, allFilters) => {
  if (userSelectedFilter["assignees"].length === 0) {
    return "";
  }
  const filterData = getFilterData(
    userSelectedFilter,
    "assignees",
    allFilters,
    "ticket_type"
  );
  const assignees = filterData.map(({ id }) => id).join(",");
  return `&assignees=${assignees}&ticket_type=all`;
};

const generateTeamsOrSubTeamsString = (
  userSelectedFilter,
  item,
  allFilters
) => {
  if (userSelectedFilter[item].length === 0) {
    return "";
  }

  let itemString = item + "s";
  const filterData = getFilterData(
    userSelectedFilter,
    itemString,
    allFilters,
    "ticket_type"
  );
  return filterData
    .map(({ id, ticket_type }) => `&${item}=${id}&ticket_type=${ticket_type}`)
    .join("");
};

const generateDateStrings = (userSelectedFilter, item) => {
  const createdDateString = generateCreatedDateString(userSelectedFilter, item);
  const dueDateString = generateDueDateString(userSelectedFilter, item);
  return createdDateString + dueDateString;
};

export const generateParamString = (
  userSelectedFilter,
  handleSetBeforeParamString,
  allFilters
) => {
  let pstr = "";

  const updatedUserSelectedFilter = {
    ...userSelectedFilter,
    teams: userSelectedFilter["team"],
    sub_teams: userSelectedFilter["sub_team"],

    "closed tickets": userSelectedFilter["closed tickets"],
    "open tickets": userSelectedFilter["open tickets"],
  };

  let ticketTypeStr = [];
  let ticketTypeUpdated = false;

  pstr = Object.keys(updatedUserSelectedFilter)
    .map((item) => {
      switch (item) {
        case "status":
        case "sla_status":
          return generateStatusOrSlaString(
            updatedUserSelectedFilter,
            item,
            allFilters
          );

        case "assignees":
          return generateAssigneesString(updatedUserSelectedFilter, allFilters);

        case "team":
        case "sub_team":
          return generateTeamsOrSubTeamsString(
            updatedUserSelectedFilter,
            item,
            allFilters
          );

        case "date":
          return generateDateStrings(updatedUserSelectedFilter, item);

        case "closed tickets":
          if (updatedUserSelectedFilter["closed tickets"]) {
            ticketTypeStr.push("&ticket_type=closed");
            ticketTypeUpdated = true;
          }
          break;

        case "open tickets":
          if (updatedUserSelectedFilter["open tickets"]) {
            ticketTypeStr.push("&ticket_type=open");
            ticketTypeUpdated = true;
          }
          break;

        default:
          return "";
      }
    })
    .filter((param) => param !== "")
    .join("");

  if (ticketTypeUpdated) {
    pstr = pstr.replace(/&ticket_type=[^&]*/g, "");
    pstr += ticketTypeStr.join("");
  }

  if (handleSetBeforeParamString) {
    handleSetBeforeParamString(pstr);
  }

  return generateDateParamString(pstr, updatedUserSelectedFilter.closedDate);
};

export const handleDateChange = (
  dateChangeValue,
  dateType,
  dateCreatedDue,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  setFilterOptions(
    filterOptions.map((filterOption) => {
      if (
        filterOption.type === "date" ||
        filterOption.type === "dashboardDate"
      ) {
        return {
          ...filterOption,
          checked: true,
          suboptions: checkRelevantDateSubtype(
            filterOption.suboptions,
            dateCreatedDue
          ),
        };
      } else return filterOption;
    })
  );

  if (dateCreatedDue === "Date created" && dateType === "From") {
    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...userSelectedFilter,
        date: {
          ...userSelectedFilter["date"],
          ["Date created"]: {
            ...userSelectedFilter["date"]["Date created"],
            ["From"]: `${dateChangeValue}`,
          },
        },
      };
    });
  } else if (dateCreatedDue === "Date created" && dateType === "To") {
    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...userSelectedFilter,
        date: {
          ...userSelectedFilter["date"],
          ["Date created"]: {
            ...userSelectedFilter["date"]["Date created"],
            ["To"]: `${dateChangeValue}`,
          },
        },
      };
    });
  } else if (dateCreatedDue === "Date due" && dateType === "From") {
    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...userSelectedFilter,
        date: {
          ...userSelectedFilter["date"],
          ["Date due"]: {
            ...userSelectedFilter["date"]["Date due"],
            ["From"]: `${dateChangeValue}`,
          },
        },
      };
    });
  } else if (dateCreatedDue === "Date due" && dateType === "To") {
    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...userSelectedFilter,
        date: {
          ...userSelectedFilter["date"],
          ["Date due"]: {
            ...userSelectedFilter["date"]["Date due"],
            ["To"]: `${dateChangeValue}`,
          },
        },
      };
    });
  } else if (dateCreatedDue === "Custom date") {
    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...userSelectedFilter,
        dashboardDate: {
          ...defaultUserSelectedFilter.dashboardDate,
          ["Custom date"]: {
            ...userSelectedFilter["dashboardDate"]["Custom date"],
            [dateType]: `${dateChangeValue}`,
          },
        },
      };
    });
  } else {
    setUserSelectedFilter((userSelectedFilter) => {
      return {
        ...userSelectedFilter,
        dashboardDate: {
          ...defaultUserSelectedFilter.dashboardDate,
          [dateCreatedDue]: {
            ...userSelectedFilter["dashboardDate"][dateCreatedDue],
            ["From"]: `${dateChangeValue}`,
          },
        },
      };
    });
  }
};

export const checkRelevantDateSubtype = (suboptions, dateType) => {
  return suboptions.map((suboption) => {
    if (suboption.type === dateType) {
      return {
        ...suboption,
        checked: true,
      };
    } else
      return {
        ...suboption,
        checked: false,
      };
  });
};

export const handleDateCreatedDueDate = (
  e,
  childFilter,
  filterOptions,
  setFilterOptions
) => {
  childFilter.showsuboptions = true;

  setFilterOptions(
    filterOptions.map((filter) => {
      if (filter.type == "date") {
        return {
          ...filter,
          suboptions: filter.suboptions.map((suboption) => {
            if (suboption.type === e.target.innerHTML) {
              return {
                ...suboption,
                showsuboptions: true,
              };
            } else {
              return {
                ...suboption,
                showsuboptions: false,
              };
            }
          }),
        };
      }

      return filter;
    })
  );
};

const findUpdatedClosedDateSuboptions = (suboptions, childFilterType) => {
  return suboptions.map((suboption) => {
    if (suboption.type === childFilterType) {
      return {
        ...suboption,
        showsuboptions: true,
      };
    } else {
      return {
        ...suboption,
        showsuboptions: false,
      };
    }
  });
};

const handleClosedDate = (filterOptions, setFilterOptions, childFilterType) => {
  setFilterOptions(
    filterOptions.map((filterOption) => {
      if (filterOption.type === "closedDate") {
        return {
          ...filterOption,
          suboptions: findUpdatedClosedDateSuboptions(
            filterOption.suboptions,
            childFilterType
          ),
        };
      } else return filterOption;
    })
  );
};

export const subFilterAssigneeNameStyle = (userName) => {
  return String(userName).charAt(0);
};

export const subFilterNameStyle = (userName) => {
  return String(userName).charAt(0).toUpperCase() + String(userName).slice(1);
};

export const formatDateFilterDropdown = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const year = date.getFullYear().toString().slice(2);

    return `${day}/${month}/${year}`;
  }
  return "";
};

export const getUpdatedItemKey = (itemKey) => {
  if (itemKey == "teams") return "team";
  else if (itemKey == "sub_teams") return "sub_team";

  return itemKey;
};

export const findChildFilterSubElements = (
  parentFilterType,
  childFilter,
  filterOptions,
  setFilterOptions,
  userSelectedFilter,
  setUserSelectedFilter
) => {
  if (parentFilterType === "date") {
    return (
      <div className="date_main_container">
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleDateCreatedDueDate(
              e,
              childFilter,
              filterOptions,
              setFilterOptions
            );
          }}
          className="date-sub-menu-container"
        >
          <div className={`${childFilter.type}-sub-menu-text`}>
            {childFilter.type}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ChevronRightIcon />
          </div>
        </div>
        {childFilter.showsuboptions && (
          <div className="fromTo">
            {childFilter.suboptions.map((suboption) => {
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="date_last_level"
                >
                  <DatePicker
                    selected={
                      userSelectedFilter["date"][`${childFilter.type}`][
                        `${suboption.type}`
                      ]
                    }
                    // className="custom-date-picker"
                    customInput={
                      <div className="datePickerBtnContainer">
                        <div className="calendar_icon">
                          <CalendarTodayOutlinedIcon />
                        </div>
                        <div className="date_picker_type">{suboption.type}</div>
                        <div className="date_picker_value">
                          {formatDateFilterDropdown(
                            userSelectedFilter["date"][`${childFilter.type}`][
                              `${suboption.type}`
                            ]
                          )}
                        </div>
                      </div>
                    }
                    onChange={(date) => {
                      handleDateChange(
                        formatDateValue(date),
                        suboption.type,
                        childFilter.type,
                        filterOptions,
                        setFilterOptions,
                        userSelectedFilter,
                        setUserSelectedFilter
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  } else if (parentFilterType === "closedDate") {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <DatePicker
          customInput={
            <div className="datePickerBtnContainer">
              <div className="calendar_icon">
                <CalendarTodayOutlinedIcon />
              </div>
              <div className="date_picker_type">{childFilter.type}</div>
              <div className="date_picker_value">
                {formatDateFilterDropdown(
                  userSelectedFilter["closedDate"][`${childFilter.type}`]
                )}
              </div>
            </div>
          }
          onChange={(date) => {
            setFilterOptions(
              filterOptions.map((filterOption) => {
                if (filterOption.type === "closedDate") {
                  return {
                    ...filterOption,
                    checked: true,
                    suboptions: getUpdatedSuboptionsAfterRemoving(
                      filterOption.suboptions,
                      childFilter.type
                    ),
                  };
                } else return filterOption;
              })
            );

            setUserSelectedFilter((userSelectedFilter) => {
              return {
                ...userSelectedFilter,
                closedDate: {
                  ...userSelectedFilter.closedDate,
                  [childFilter.type]: formatDateValue(date),
                },
              };
            });
          }}
        />
      </div>
    );
  }

  return (
    <div className="assignee_sub_filter">
      {parentFilterType === "assignees" ? (
        <div
          className="firstLetter"
          style={{
            width: "28px",
            height: "28px",
            fontWeight: "bolder",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "28px",
            color: "white",
            backgroundColor: `${childFilter.colour}`,
          }}
        >
          {subFilterAssigneeNameStyle(childFilter.type)}
        </div>
      ) : (
        ""
      )}
      {childFilter.checked ? (
        <div style={{ fontWeight: "600" }} className="assignee-value">
          {subFilterNameStyle(childFilter.type)}
        </div>
      ) : (
        <div className="assignee_value">
          {subFilterNameStyle(childFilter.type)}
        </div>
      )}
      <div>
        <Checkbox
          checked={childFilter.checked}
          onClick={(e) =>
            childFilter.checked
              ? handleChildFilterUnchecked(
                  e,
                  parentFilterType,
                  childFilter,
                  filterOptions,
                  setFilterOptions,
                  userSelectedFilter,
                  setUserSelectedFilter
                )
              : handleParentClick(
                  e,
                  parentFilterType,
                  childFilter,
                  filterOptions,
                  setFilterOptions,
                  userSelectedFilter,
                  setUserSelectedFilter
                )
          }
          sx={{
            color: "rgba(192, 195, 206, 1)",
            "&.Mui-checked": {
              color: "rgba(98, 0, 234, 1)",
            },
          }}
          className="chip_checkbox"
        />
      </div>
    </div>
  );
};
