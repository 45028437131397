import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "../../assets/Search.svg";
import "./style.css";
import { isManagerOrSuperUser } from "../../utils/common_functions";
import { CircularLoader } from "../CircularLoader";
import { UsersTable } from "../UsersTable";
import TablesHead from "../TablesHead";
import { checkUserShift } from "../../utils/TableWithActionColumnFunctions";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:selected": {
    backgroundColor: "#f3ebff",
  },
}));

export const TableWithActionColumn = ({
  rowdata,
  columndata,
  isLoading,
  fetchUsersSeachList,
  fetchData,
  url,
}) => {
  const [selected, setSelected] = React.useState([]);

  const [searchText, setSearchText] = React.useState("");
  const [hasUserSearched, setHasUserSearched] = React.useState(false);
  const [debouncedValue, setDebouncedValue] = React.useState("");

  React.useEffect(() => {
    const sid = setTimeout(() => {
      setDebouncedValue(searchText);
    }, 1000);
    return () => clearTimeout(sid);
  }, [searchText]);

  React.useEffect(() => {
    if (hasUserSearched) fetchUsersSeachList(debouncedValue);
  }, [debouncedValue]);

  React.useEffect(() => {
    if (!isLoading) {
      const tableDivHeight =
        document.getElementsByClassName("tableMainContainer")[0].clientHeight;
      const tableDataHeignt =
        document.getElementsByClassName("usersDataTable")[0].clientHeight;
      // tableDataHeignt > 100 used to avoid empty table case, assuming when data will
      //  be there tableDataHeignt will be more than 100
      if (tableDataHeignt > 100 && tableDataHeignt <= tableDivHeight) {
        fetchData();
      }
    }

    const handleScroll = (e) => {
      const clientHeight = e.target.clientHeight;
      const scrollTop = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;

      const sum = Math.trunc(clientHeight + scrollTop - scrollHeight);

      if (sum >= -100 && isLoading === false) {
        fetchData();
      }
    };

    const elem = document.getElementsByClassName("tableMainContainer")[0];
    elem.addEventListener("scroll", handleScroll);

    return () => elem.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  const handleAddUser = (event) => {
    window.location.href = "/add-user/?user=new-user";
  };
  const getQueryParamsForEditUser = (row) => {
  
    return `name=${row.id}`;
  };
  const handleEditUser = (event, row) => {
    const queryParamsForEditUser = getQueryParamsForEditUser(row);
    window.location.href = `/edit-user/?${queryParamsForEditUser}`;
  };

  const handleChangeShift = (event, row) => {
   
    const userID = `?userid=${row.id}`;
    const shiftID = row.user_shift.id
      ? `&userShiftId=${row.user_shift.id}`
      : "";

    window.location.href = `/change-shift/${userID}${shiftID}`;
  };

  return (
    <Grid
      sx={{
        paddingLeft: "1em",
        overflow: "hidden",
        paddingRight: "20px",
        width: "95%",
        maxHeight: "100vh",
        backgroundColor: "transparent",
      }}
    >
      <Grid container className="headingContainer" spacing={2}>
        <Grid item className="teamHeading" xs={8}>
          Users
        </Grid>

        <Grid
          sx={{
            justifyContent: "end",
          }}
          item
          container
          xs={4}
        >
          <Grid
            item
            className="span-8"
            style={{
              border: "1px solid var(--Base-Blue-grey-5, #C0C3CE)",
              marginTop: "2rem",
              display: "flex",
              justifyContent: "start",
              width: "15rem",
              height: "2.25rem",
              borderRadius: "0.5rem",
            }}
          >
            <img loading="lazy" src={SearchIcon} />

            <input
              style={{ width: "auto" }}
              type="text"
              className="div-16"
              placeholder={"Search user"}
              onChange={(e) => {
                setHasUserSearched(true);
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Grid>
          {isManagerOrSuperUser() && (
            <Grid item sx={{ marginTop: "2rem", marginLeft: "1rem" }}>
              <Button onClick={handleAddUser} className="addTeamButton">
                {" "}
                <AddIcon fontSize="small" /> Add user
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <TableContainer sx={{ maxHeight: "87vh" }} className="tableMainContainer">
        <Table
          className="usersDataTable"
          stickyHeader
          aria-label="sticky table"
          size="small"
          sx={{ tableLayout: "auto" }}
        >
          <TablesHead headCells={columndata} />
          <TableBody style={{ zIndex: 0 }}>
            {rowdata.map((row, index) => {
              return (
                <UsersTable
                  row={row}
                  key={row.id}
                  handleEditUser={handleEditUser}
                  handleChangeShift={handleChangeShift}
                  index={index}
                  checkUserShift={checkUserShift}
                />
              );
            })}
            {isLoading && (
              <StyledTableRow>
                <TableCell
                  align="left"
                  className="tableCells"
                  padding="normal"
                  colSpan={columndata.length}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <CircularLoader />
                </TableCell>
              </StyledTableRow>
            )}
            {!url && !isLoading && (
              <StyledTableRow>
                <TableCell
                  align="left"
                  className="tableCells"
                  padding="normal"
                  colSpan={columndata.length}
                  style={{
                    textAlign: "center",
                  }}
                >
                  No more data
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
