import SearchIcon from "../../assets/Search.svg";
import Plus from "../../assets/Plus.svg";
import FilterDropdown from "../../assets/FilterDropdown.svg";
import ExportsIcon from "../../assets/ExportsIcon.svg";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { handleParentClick } from "../../utils/clearAllSelectedFilters";
import { ColorRing } from "react-loader-spinner";

const Header = (props) => {
  const {
    heading,
    handleInputChange,
    updateHandleInputChange,
    searchFieldStyle,
    inputValue,
    buttonTitle,
    showFiltersDropdown,
    onMouseOver,
    handleParentFilterUnchecked,
    parentFilterOptions,
    getSubFilterClassName,
    onKeyUp,
    FilterSubOptions,
    handleOnDateHover,
    handleChildClick,
    findChildFilterSubElements,
    filterOptions,
    setFilterOptions,
    userSelectedFilter,
    setUserSelectedFilter,
    displaySelectedFilters,
    checkClearAllVisibility,
    clearAllSelectedFilters,
    style,
    handleExport,
    exportButtonDisplay,
    exportButtonTitle,
  } = props;


  const handleClick = (
    e,
    filter,
    index,
    filterOptions,
    setFilterOptions,
    userSelectedFilter,
    setUserSelectedFilter
  ) => {
    if (filter.type === "closed tickets" || filter.type === "open tickets") {
      handleParentClick(
        e,
        index,
        filter,
        filterOptions,
        setFilterOptions,
        userSelectedFilter,
        setUserSelectedFilter
      );
    }
  };

  return (
    <>
      <span className="span-7">
        <div className="div-14">{heading}</div>
        <div className="div-15" style={searchFieldStyle}>
          <span className="span-8">
            <img
              className="img-8"
              loading="lazy"
              src={SearchIcon}
              alt="serach"
            />
            <input
              type="text"
              value={inputValue}
              onChange={(e) =>
                handleInputChange(e.target.value, updateHandleInputChange)
              }
              className="div-16"
              placeholder="Search tickets, assignee"
            />
          </span>

          <Button
            style={{
              background: "var(--Interactive-Bg-P1, #00E676)",
              color: "color: var(--Interactive-Label-P1-P2-E1, #262626)",
              textTransform: "capitalize",
              display: "none",
            }}
            variant="contained"
            startIcon={
              <img loading="lazy" src={Plus} className="img-9" alt="plus" />
            }
          >
            {buttonTitle}
          </Button>
        </div>
      </span>
      <div className="div-19">
        <div
          style={{
            display: "flex",
          }}
        >
          <div className="filters">
            <span className="span-10" onClick={showFiltersDropdown}>
              <div className="div-20">Filters</div>
              <img
                loading="lazy"
                src={FilterDropdown}
                className="img-10"
                alt="dropDown"
              />
            </span>
            <div
              className="filters_dropdown"
              data-testid="filters-dropdown-menu"
            >
              {filterOptions.map((filter, index) => {
            
                return (
                  <div className="filter_dropdown_option" key={index}>
                    <div
                      data-testid={`parent_filter_${filter.type}`}
                      onMouseOver={(e) =>
                        onMouseOver(
                          e,
                          index,
                          filter,
                          filterOptions,
                          setFilterOptions,
                          userSelectedFilter,
                          setUserSelectedFilter
                        )
                      }
                      onClick={(e) =>
                        handleClick(
                          e,
                          filter,
                          index,
                          filterOptions,
                          setFilterOptions,
                          userSelectedFilter,
                          setUserSelectedFilter
                        )
                      }
                      className={`parent_filter_option ${
                        filter.isHighlighted ? "elem_highlighted" : ""
                      }`}
                    >
                      <div>
                        <Checkbox
                          checked={filter.checked}
                          onClick={(e) =>
                            filter.checked
                              ? handleParentFilterUnchecked(
                                  e,
                                  filter,
                                  filterOptions,
                                  setFilterOptions,
                                  userSelectedFilter,
                                  setUserSelectedFilter
                                )
                              : handleClick(
                                  e,
                                  filter,
                                  index,
                                  filterOptions,
                                  setFilterOptions,
                                  userSelectedFilter,
                                  setUserSelectedFilter
                                )
                          }
                          sx={{
                            color: "rgba(192, 195, 206, 1)",
                            padding: "0",
                            "&.Mui-checked": {
                              color: "rgba(98, 0, 234, 1)",
                            },
                          }}
                        />
                      </div>
                      <div>{parentFilterOptions(filter.type)}</div>
                      {filter.type !== "closed tickets" &&
                        filter.type !== "open tickets" && (
                          <ChevronRightIcon style={{ marginLeft: "auto" }} />
                        )}
                    </div>
                    {filter.showsuboptions && (
                      <div className={getSubFilterClassName(filter.type)}>
                        {filter.type !== "date" &&
                          filter.type !== "closedDate" &&
                          filter.type !== "open tickets" && (
                            <div className="search_box">
                              <div
                                className="sub_filter_search_box_container"
                                style={{
                                  paddingLeft: "0.8rem",
                                  marginRight: "0.4rem",
                                }}
                              >
                                <img src={SearchIcon} alt={"search"} />
                              </div>
                              <div>
                                <input
                                  type="text"
                                  placeholder="Search..."
                                  className="searchInput"
                                  onKeyUp={onKeyUp}
                                />
                              </div>
                            </div>
                          )}
                        {filter.suboptions.map((sub, childIndex) => {
                          return (
                            <FilterSubOptions
                              key={sub.id}
                              handleOnDateHover={handleOnDateHover}
                              handleChildClick={handleChildClick}
                              filter={filter}
                              sub={sub}
                              childIndex={childIndex}
                              findChildFilterSubElements={
                                findChildFilterSubElements
                              }
                              index={index}
                              filterOptions={filterOptions}
                              setFilterOptions={setFilterOptions}
                              userSelectedFilter={userSelectedFilter}
                              setUserSelectedFilter={setUserSelectedFilter}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="display_selected_filters_dashboard">
            {displaySelectedFilters}

            <Button
              style={{
                visibility: checkClearAllVisibility,
                color: "rgba(103, 107, 126, 1)",
                textDecoration: "underline",
                textTransform: "capitalize",
              }}
              onClick={clearAllSelectedFilters}
              variant="text"
            >
              Clear all
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <ColorRing
            visible={true}
            height="3rem"
            width="3rem"
            ariaLabel="color-ring-loading"
            wrapperStyle={style}
            wrapperClass="color-ring-wrapper"
            colors={["#6200ea", "#6200ea", "#6200ea", "#6200ea", "#6200ea"]}
          />

          <Button
            data-testid="export_btn"
            onClick={handleExport}
            variant="contained"
            startIcon={
              <img
                loading="lazy"
                src={ExportsIcon}
                className="img-11"
                alt="export"
              />
            }
            style={{
              background: "var(--Interactive-Bg-T1, #F5F5F5)",
              color: "var(--True-Grey-9, var(--Base-Grey-9, #262626))",
              textTransform: "capitalize",
              height: "100%",
              display: exportButtonDisplay,
            }}
          >
            {exportButtonTitle}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Header;
