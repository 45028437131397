import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CollapsibleSideDrawer } from "../CollapsibeSideDrawer";
import Grid from "@mui/material/Grid";
import { Icon } from "@mui/material";
import BuilderLogo from "../../assets/Builder_logo.png";
import BuilderLogoB from "../../assets/BuilderLogoB.svg";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./styles.css";
import { CustomUI } from "../Modal";
import { logout } from "../../config";
import LogoutIcon from "@mui/icons-material/Logout";

const drawerWidth = 200;
const HeaderDrawer = (props) => {
  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeInOut,
      // duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeInOut,
      // duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  return (
    <>
      <CssBaseline />
      <Drawer variant="permanent" open={props.open} className="drawerDiv">
        <Grid className="LogoGrid">
          {props.open === true ? (
            <img
              onClick={props.handleDrawerClose}
              loading="lazy"
              alt="Builder Logo"
              src={BuilderLogo}
              className="BuilderLogo"
            />
          ) : (
            <img
              onClick={props.handleDrawerOpen}
              loading="lazy"
              alt="Builder Logo"
              src={BuilderLogoB}
              className="BuilderLogoB"
            />
          )}
        </Grid>
        <Grid>
          <CollapsibleSideDrawer open={props.open} />
        </Grid>
        <Grid container spacing={2} className="userprofile">
          <Grid item>
            <Icon sx={{ height: "auto", width: "auto" }}>
              <img
                src={localStorage.getItem("user_photo")}
                alt="userphoto"
                className="user_logo"
              />
            </Icon>
          </Grid>
          {props.open === true ? (
            <Grid item className="user_name" sx={{ marginTop: "0.5rem" }}>
              {" "}
              {localStorage.getItem("user_name")}
            </Grid>
          ) : (
            <></>
          )}
          <CustomUI
            src={
              <LogoutIcon
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              />
            }
            title="Logout"
            closeButtonTitle="Go back"
            functionalButtonTitle="Logout"
            handleClick={logout}
          />
        </Grid>
      </Drawer>

      <div className={props.open ? "closeOpenArrowActive" : "closeOpenArrow"}>
        {props.open === true ? (
          <IconButton
            onClick={props.handleDrawerClose}
            className="closeArrowButton"
          >
            <ChevronLeftIcon style={{ color: "#651FFF" }} />
          </IconButton>
        ) : (
          <IconButton
            onClick={props.handleDrawerOpen}
            className="closeArrowButton"
          >
            <ChevronRightIcon style={{ color: "#651FFF" }} />
          </IconButton>
        )}
      </div>
    </>
  );
};
HeaderDrawer.propTypes = {};

export default React.memo(HeaderDrawer);
