import { useState, useEffect } from "react";
import EditTeamPage from "../../components/EditTeamPage";
import {
  customFetch,
  customAPICallWithPayload,
  REACT_APP_API_PATH as baseURL,
} from "../../config";
import { response_messages } from "./message";
import { ToastContainer } from "react-toastify";
import {
  successNotify,
  errorNotifyList,
  errorNotify,
} from "../../components/ToastComponent";
import {
  redirectToTicketSumary,
  isManagerOrSuperUser,
} from "../../utils/common_functions";
import {ErrorComponent} from "../Error";

const successNotifyandRedirect = (response_message) => {
  successNotify(response_message);
  setTimeout(() => {
    window.location.href = `/teams`;
  }, 2000);
};

const makeRequestApi = async (URI, data_payload, method, setIsLoading) => {
  setIsLoading(true);
  const response = await customAPICallWithPayload(URI, method, data_payload);
  response == "success"
    ? successNotifyandRedirect(response_messages[method])
    : errorNotifyList(response);
};

function EditTeam() {
  const access_type = window.location.pathname?.split("/")[2]?.split("-")[0];
  const locationObject = new URLSearchParams(window.location.search);
  const receivedValue = locationObject.get("team");
  const clickup_list_id = locationObject.get("clickup_list_id");

  const table_url = `${baseURL}v1/team/${receivedValue}`;
  const dropdown_list_url = `${baseURL}v1/custom_fields/`;

  const url_for_patch = `${baseURL}v1/team/${receivedValue}/`;
  const url_for_create = `${baseURL}v1/team/`;

  const url_for_enableTeamAutomation = `${baseURL}v1/team/${receivedValue}/enable-team-automation`;

  const subteam_custom_field = `${baseURL}v1/clickup/custom_fields/${clickup_list_id}`;

  const dataForNewTeam = ["no call to be made"];

  const [rowData, setRowData] = useState({
    data: [],
    custom_field: [],
  });
  // clickup list
  const [spaces, setSpaces] = useState([]);
  const [folders, setFolders] = useState([]);
  const [lists, setLists] = useState([]);
  const [listsWithoutFolder, setListsWithoutFolder] = useState([]);
  const [openItems, setOpenItems] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const fetchTableData = async (table_list_url, custom_field_list_url) => {
    setIsLoading(true);
    setRowData({
      data:
        receivedValue === "new-team"
          ? dataForNewTeam
          : await customFetch(table_list_url, "GET"),
      custom_field: await customFetch(custom_field_list_url, "GET"),
      subteam_custom_field: !["add"].includes(access_type)
        ? (await customFetch(subteam_custom_field, "GET"))?.filter(
            (item) => item.type == "drop_down"
          )
        : "",
    });
  };

  useEffect(
    () => {
      fetchTableData(table_url, dropdown_list_url);
      setIsLoading(false);
    },
    [rowData["data"]?.length, rowData["custom_field"]?.length],
    isLoading
  );

  const getDataForApi = (datafromForm) => {
    receivedValue === "new-team"
      ? makeRequestApi(url_for_create, datafromForm, "POST", setIsLoading)
      : makeRequestApi(url_for_patch, datafromForm, "PATCH", setIsLoading);
  };

  const getDataForEnableAutomation = () => {
    makeRequestApi(url_for_enableTeamAutomation, {}, "PATCH", setIsLoading);
  };

  const deactivateSubTeam = async (id) => {
    const URI = `${baseURL}v1/sub_team/${id.id}`;
    const data_payload = { ...id, is_active: "false" };
    const response = await customAPICallWithPayload(URI, "PUT", data_payload);
    response == "success"
      ? successNotify("Sub team deleted")
      : errorNotify(response);

    fetchTableData(table_url, dropdown_list_url);
    setIsLoading(false);
  };

  const handleUpdateOrCreateSubTeam = async (data, dataForPatch) => {
    const URI = `${baseURL}v1/sub_team/`;
    const data_payload = data;
    setIsLoading(true);
    const response = await customAPICallWithPayload(URI, "POST", data_payload);
    response == "success"
      ? successNotify("Sub team added")
      : errorNotify(response);

    await customAPICallWithPayload(url_for_patch, "PATCH", dataForPatch);

    await fetchTableData(table_url, dropdown_list_url);
    setIsLoading(false);
  };

  const fetchSpaceData = async () => {
    const response = await customFetch(`${baseURL}v1/clickup/spaces/`, "GET");

    setSpaces(response);
    return response;
  };

  const fetchFolderData = async (spaceId) => {
    const response = await customFetch(
      `${baseURL}v1/clickup/spaces/${spaceId}/folders/`,
      "GET"
    );

    setFolders((prevFolders) => ({ ...prevFolders, [spaceId]: response }));
    return response;
  };

  const fetchListData = async (folderId) => {
    const response = await customFetch(
      `${baseURL}v1/clickup/folders/${folderId}/lists/`,
      "GET"
    );

    setLists((prevLists) => ({ ...prevLists, [folderId]: response }));
    return response;
  };

  const fetchListWithoutFolderData = async (spaceId) => {
    const response = await customFetch(
      `${baseURL}v1/clickup/spaces/${spaceId}/lists_without_folder/`,
      "GET"
    );

    setListsWithoutFolder((prevListsWithoutFolder) => ({
      ...prevListsWithoutFolder,
      [spaceId]: response,
    }));
    return response;
  };

  const handleItemClick = (itemId) => {
    setOpenItems({ ...openItems, [itemId]: !openItems[itemId] });
  };

  return (
    <>
      {isManagerOrSuperUser() ? (
        <EditTeamPage
          page={receivedValue}
          formData={rowData["data"]}
          access_type={access_type}
          isLoading={isLoading}
          custom_field_dropdown={rowData["custom_field"]}
          getDataForPutApi={getDataForApi}
          getDataForEnableAutomation={getDataForEnableAutomation}
          deactivateSubTeam={deactivateSubTeam}
          handleUpdateOrCreateSubTeam={handleUpdateOrCreateSubTeam}
          customFieldDropdownValues={rowData["subteam_custom_field"]}
          spaces={spaces}
          folders={folders}
          lists={lists}
          listsWithoutFolder={listsWithoutFolder}
          fetchSpaceData={fetchSpaceData}
          fetchFolderData={fetchFolderData}
          fetchListData={fetchListData}
          fetchListWithoutFolderData={fetchListWithoutFolderData}
          handleItemClick={handleItemClick}
          openItems={openItems}
        />
      ) : (
        <ErrorComponent
          errorMessage="You do not have access to view this page"
          handleRefresh={redirectToTicketSumary}
          firstButtonText="Go back"
        />
      )}
      <ToastContainer autoClose={2000} />
    </>
  );
}
export default EditTeam;
