export const defaultFiltersForTicketSummary = {
    assignees: [],
    status: [],
    team: [],
    sub_team: [],
    date: {
      ["Date created"]: {
        From: "",
        To: "",
      },
      ["Date due"]: {
        From: "",
        To: "",
      },
    },
    closedDate: {
      From: "",
      To: "",
    },
    sla_status: [],
  }