import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _without from "lodash/without";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import "./style.css";
import { SaveCancel } from "../SaveCancel";
import {
  customFetch,
  REACT_APP_API_PATH as baseURL,
  customAPICallWithPayload,
} from "../../config";
import { Radio } from "@mui/material";
import { capitalizeFirstLetter } from "../../utils/clearAllSelectedFilters";
import { Loader } from "../Loader";

const squadsList = ["l1", "l2", "l3"];

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "11em",
      minWidth: "10vw",
      padding: "0 0 0 0",
    },
    sx: {
      "& .MuiTypography-root": {
        fontSize: "14px",
      },
    },
  },
};

const getLastName = (lastname) => {
  return lastname || "";
};

const teamIDsUpdate = (item, teamsItem, teamIDs) => {
  const isItemCheck = item === teamsItem.name;
  return isItemCheck ? teamIDs.push(teamsItem.id) : teamIDs;
};

const addTeamsIDs = (teamIDs, teamsList, item) => {
  Object.values(teamsList).forEach((teamsItem) => {
    teamIDsUpdate(item, teamsItem, teamIDs);
  });
};

const getUpdatedTeams = (value) => {
  return typeof value === "string" ? value.split(",") : value;
};

const handleDelete = (
  e,
  value,
  type,
  handleDeleteSquad,
  handleDeleteTeam,
  handleDeleteSubTeam
) => {
  e.preventDefault();

  if (type === "Squad") {
    handleDeleteSquad(value);
  } else if (type === "Team") {
    handleDeleteTeam(value);
  } else if (type === "Sub Team") {
    handleDeleteSubTeam(value);
  }
};

const checkIfDisabled = () => {
  return !(
    localStorage.getItem("is_manager") === "true" ||
    localStorage.getItem("is_superuser") === "true"
  );
};

const checkIsAManager = (handleManagerToggle, isManager) => {
  return checkIfDisabled() ? (
    <Switch
      data-testid="isManager"
      checked={isManager}
      disabled
      onClick={handleManagerToggle}
    />
  ) : (
    <Switch checked={isManager} onClick={handleManagerToggle} />
  );
};

const checkColorPicker = (handleChangeColor, colorCode) => {
  return checkIfDisabled() ? (
    <input
      data-testid="colorCodePicker"
      type="color"
      disabled
      onChange={handleChangeColor}
      value={colorCode}
      style={{
        borderWidth: 0,
      }}
    />
  ) : (
    <input
      data-testid="colorCodePicker"
      type="color"
      onChange={handleChangeColor}
      value={colorCode}
      style={{
        borderWidth: 0,
      }}
    />
  );
};

const getPageHeading = (page, existingusername) => {
  return page.includes("Edit user") ? `Edit user - ${existingusername}` : page;
};

const updateUserData = async (
  dataToBeSaved,
  successNotifyandRedirect,
  errorNotifyList,
  userID,
  existinguseremail
) => {
  const url = `${baseURL}v1/user/${userID}`;

  const data_payload = dataToBeSaved;

  if(!('email' in data_payload) && existinguseremail.length>0)
    data_payload.email=existinguseremail;

  const response = await customAPICallWithPayload(url, "PATCH", data_payload);
  response == "success"
    ? successNotifyandRedirect("Success: User updated successfully")
    : errorNotifyList(response);
};

const addNewUserData = async (
  dataToBeSaved,
  successNotifyandRedirect,
  errorNotifyList
) => {
  const url = `${baseURL}v1/user/`;

  const data_payload = dataToBeSaved;

  if (!("is_manager" in data_payload)) data_payload["is_manager"] = false;

  const keysCollection = Object.keys(data_payload);

  if (!keysCollection.includes("color_code"))
    data_payload["color_code"] = "#000000";

  const response = await customAPICallWithPayload(url, "POST", data_payload);
  response == "success"
    ? successNotifyandRedirect("Success: User added successfully")
    : errorNotifyList(response);
};

const checkIfDisabledOrNormalChips = (
  key,
  field,
  type,
  getNormalChips,
  getDisabledChips
) => {
  return checkIfDisabled()
    ? getDisabledChips(key, field, type)
    : getNormalChips(key, field, type);
};

const BasicDetailsCustomisedChips = ({
  customField,
  type,
  getDisabledChips,
  getNormalChips,
}) => {
  if (customField.length === 0) return;

  if (typeof customField === "string") {
    return checkIfDisabled()
      ? getDisabledChips(customField, customField, type)
      : getNormalChips(customField, customField, type);
  } else
    return (
      <Stack direction="row" spacing={1}>
        {customField
          .slice(0, 3)
          .map((field, key) =>
            checkIfDisabledOrNormalChips(
              key,
              field,
              type,
              getNormalChips,
              getDisabledChips
            )
          )}
        {customField.length - 3 > 0 ? (
          checkIfDisabledOrNormalChips(
            "+" + (customField.length - 3),
            "+" + (customField.length - 3),
            type,
            getNormalChips,
            getDisabledChips
          )
        ) : (
          <></>
        )}
      </Stack>
    );
};

const GetBasicDetails = ({
  username,
  existinguseremail,
  handleNameChange,
  handleEmailChange,
}) => {
  return (
    <Grid className="basicDetails">
      <h3>Basic details</h3>
      <Divider />
      <Grid sx={{ padding: "16px 0px", color: "#3C3E49" }}>
        <Grid>
          <span className="mandatoryAsterik">*</span>Name
        </Grid>
        <Grid>
          <TextField
            data-testid="Enter your name"
            id="outlined-basic"
            variant="outlined"
            sx={{ width: "74vw", padding: " 0.8em 1em 0em 0em" }}
            type="text"
            size="small"
            // value={existingusername}
            value={username}
            onChange={(e) => handleNameChange(e)}
          />
        </Grid>
      </Grid>{" "}
      <Grid sx={{ padding: "16px 0px", color: "#3C3E49" }}>
        <Grid>
          <span className="mandatoryAsterik">*</span>Email
        </Grid>
        <Grid>
          <TextField
            data-testid="SelectEmail"
            id="outlined-basic"
            variant="outlined"
            sx={{ width: "74vw", padding: " 0.8em 1em 0em 0em" }}
            type="text"
            size="small"
            value={existinguseremail}
            onChange={(e) => handleEmailChange(e)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const EdituserPage = ({ page, successNotify, errorNotifyList }) => {
  const [teamsList, setTeamsList] = React.useState([]);
  const [subTeamsList, setSubTeamsList] = React.useState([]);

  const [userID, setUserID] = React.useState(null);

  const [username, setUsername] = React.useState("");

  const [saveButtonStatus, setSaveButtonStatus] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [existingusername, setExistingUserName] = React.useState("");
  const [existinguseremail, setExistingUserEmail] = React.useState("");
  const [squadList, setSquadList] = React.useState("");
  const [teamList, setTeamList] = React.useState([]);
  const [subTeamList, setSubTeamList] = React.useState([]);
  const [isManager, setIsManager] = React.useState(false);
  const [colorCode, setColorCode] = React.useState("#000000");

  const [dataToBeSaved, setDataToBeSaved] = React.useState({});

  const getSelectedTeamIDs = (teamList) => {
    const teamIDs = [];

    Object.values(teamList).forEach((item) => {
      addTeamsIDs(teamIDs, teamsList, item);
    });

    return teamIDs;
  };

  const getSelectedSubTeamIDs = (subTeamList) => {
    const subTeamIDs = [];

    Object.values(subTeamList).forEach((item) => {
      addTeamsIDs(subTeamIDs, subTeamsList, item);
    });

    return subTeamIDs;
  };

  const handleGoBack = () => {
    window.location.href = `/users`;
  };

  const handleChangeSquad = (event) => {
    setDataToBeSaved({
      ...dataToBeSaved,
      squad_name: event.target.value,
    });

    setSquadList(event.target.value);

    setSaveButtonStatus(true);
  };

  const handleChangeTeam = (event) => {
    const {
      target: { value },
    } = event;

    const updatedTeams = getUpdatedTeams(value);

    setTeamList(updatedTeams);

    setDataToBeSaved({
      ...dataToBeSaved,
      team_ids: getSelectedTeamIDs(updatedTeams),
    });

    setSaveButtonStatus(true);

    setSaveButtonStatus(true);
  };

  const handleChangeSubTeam = (event) => {
    const {
      target: { value },
    } = event;

    const updatedSubTeams = getUpdatedTeams(value);
    setSubTeamList(updatedSubTeams);

    setDataToBeSaved({
      ...dataToBeSaved,
      sub_team_ids: getSelectedSubTeamIDs(updatedSubTeams),
    });

    setSaveButtonStatus(true);
  };

  const handleDeleteSquad = (value) => {
    setSquadList((current) => _without(current, value));
    setDataToBeSaved({
      ...dataToBeSaved,
      squad_name: squadList,
    });
    setSaveButtonStatus(true);
  };

  const handleDeleteTeam = (value) => {
    const updatedTeamList = teamList.filter((item) => item != value);

    setTeamList((current) => _without(current, value));
    setDataToBeSaved({
      ...dataToBeSaved,
      team_ids: getSelectedTeamIDs(updatedTeamList),
    });
    setSaveButtonStatus(true);
  };

  const handleDeleteSubTeam = (value) => {
    const updatedSubTeamList = subTeamList.filter((item) => item != value);

    setSubTeamList((current) => _without(current, value));
    setDataToBeSaved({
      ...dataToBeSaved,
      sub_team_ids: getSelectedSubTeamIDs(updatedSubTeamList),
    });
    setSaveButtonStatus(true);
  };

  const handleManagerToggle = () => {
    const updatedIsmanager = !isManager;
    setIsManager(!isManager);
    setDataToBeSaved({
      ...dataToBeSaved,
      is_manager: updatedIsmanager,
    });
    setSaveButtonStatus(true);
  };

  const getDisabledChips = (key, field, type) => {
    return (
      <Chip
        key={key}
        label={field}
        clickable
        disabled
        variant="outlined"
        onDelete={(e) =>
          handleDelete(
            e,
            field,
            type,
            handleDeleteSquad,
            handleDeleteTeam,
            handleDeleteSubTeam
          )
        }
        sx={{
          backgroundColor: "#F3EBFF",
          border: "none",
          fontSize: "12px",
        }}
        deleteIcon={
          <CloseIcon
            sx={{
              color: "#3C3E49 !important",
              fontSize: "15px !important",
            }}
          />
        }
      />
    );
  };

  const getNormalChips = (key, field, type) => {
    return (
      <Chip
        key={key}
        label={field}
        clickable
        variant="outlined"
        onDelete={(e) =>
          handleDelete(
            e,
            field,
            type,
            handleDeleteSquad,
            handleDeleteTeam,
            handleDeleteSubTeam
          )
        }
        sx={{
          backgroundColor: "#F3EBFF",
          border: "none",
          fontSize: "12px",
        }}
        deleteIcon={
          <CloseIcon
            sx={{
              color: "#3C3E49 !important",
              fontSize: "15px !important",
            }}
          />
        }
      />
    );
  };

  const handleChangeColor = (e) => {
    setColorCode(e.target.value);
    setDataToBeSaved({
      ...dataToBeSaved,
      color_code: e.target.value,
    });
    setSaveButtonStatus(true);
  };

  const fetchExistingUserData = async () => {
    const userId = page.split("-")[1];

    setIsLoading(true);

    const existingUser = await customFetch(`${baseURL}v1/user/${userId}`);

    setUserID(userId);
    setExistingUserName(existingUser.email.split("@")[0]);
    setExistingUserEmail(existingUser.email);
    setSquadList(existingUser.squad_name);
    setIsManager(existingUser.is_manager);
    setTeamList(existingUser.teams.map((item) => item.name));
    setSubTeamList(existingUser.sub_teams.map((item) => item.name));
    setColorCode(existingUser.color_code);

    setUsername(
      capitalizeFirstLetter(existingUser.first_name) +
        " " +
        capitalizeFirstLetter(existingUser.last_name)
    );
    setIsLoading(false);

    setSaveButtonStatus(false);
  };

  const fetchAllData = async () => {
    const allTeamsData = await customFetch(`${baseURL}v1/team`);

    setTeamsList(allTeamsData);

    const allSubTeamsData = await customFetch(`${baseURL}v1/sub_team`);

    setSubTeamsList(allSubTeamsData);
  };

  const successNotifyandRedirect = (response_message) => {
    successNotify(response_message);
    setTimeout(() => {
      window.location.href = `/users`;
    }, 2000);
  };

  const handleUserSave = (e) => {
    return page.includes("Edit user")
      ? updateUserData(
          dataToBeSaved,
          successNotifyandRedirect,
          errorNotifyList,
          userID,
          existinguseremail
        )
      : addNewUserData(
          dataToBeSaved,
          successNotifyandRedirect,
          errorNotifyList
        );
  };

  const handleUserCancel = () => {
    window.location.href = `/users`;
  };

  const handleNameChange = (e) => {
    const [firstname, lastname] = e.target.value
      .split(" ")
      .map((item) => item.toLowerCase());

    setDataToBeSaved({
      ...dataToBeSaved,
      first_name: firstname,
      last_name: getLastName(lastname),
    });
    setUsername(e.target.value);
    setSaveButtonStatus(true);
  };

  const handleEmailChange = (e) => {
    setDataToBeSaved({
      ...dataToBeSaved,
      email: e.target.value,
    });
    setExistingUserEmail(e.target.value);
    setSaveButtonStatus(true);
  };

  const isSquadRadioBtnChecked = (value) => {
    return value === squadList;
  };

  const displayNoTeams = (menuKey, menuValue) => {
    return (
      <MenuItem key={menuKey} value={menuValue} sx={{ padding: "0px" }}>
        {/* <Checkbox
          size="small"
          checked={teamList.indexOf(team.name) > -1}
          sx={{
            color: "#C0C3CE",
            "&.Mui-checked": {
              color: "#7C4DFF",
            },
          }}
        /> */}
        <ListItemText primary={menuValue} sx={{ fontSize: "14px" }} />
      </MenuItem>
    );
  };

  const checkTeamsListLength = (teamsList) => {
    return teamsList?.length === 0;
  };

  const displayAllTeams = (teamsList, teamList) => {
    return teamsList.map((team) => (
      <MenuItem key={team.name} value={team.name} sx={{ padding: "0px" }}>
        <Checkbox
          size="small"
          checked={teamList.indexOf(team.name) > -1}
          sx={{
            color: "#C0C3CE",
            "&.Mui-checked": {
              color: "#7C4DFF",
            },
          }}
        />
        <ListItemText primary={team.name} sx={{ fontSize: "14px" }} />
      </MenuItem>
    ));
  };

  const generateTeamsListDropdown = (
    teamsList,
    teamList,
    menuKey,
    menuValue
  ) => {
    return checkTeamsListLength(teamsList)
      ? displayNoTeams(menuKey, menuValue)
      : displayAllTeams(teamsList, teamList);
  };

  React.useEffect(() => {}, [
    existingusername,
    existinguseremail,
    dataToBeSaved,
    username,
    isManager,
    squadList,
    colorCode,
    teamList,
    subTeamList,
  ]);

  React.useEffect(() => {
    fetchAllData();
  }, []);

  React.useEffect(() => {
    if (page.includes("Edit user")) fetchExistingUserData();
  }, []);

  return isLoading === true ? (
    <div className="loaderDiv">
      <Loader loading={isLoading} color={"#8f5ff7"} size={20} />
    </div>
  ) : (
    <Grid
      sx={{
        paddingTop: "2em",
        backgroundColor: "white",
      }}
    >
      {/* back arrow and heading */}
      <Grid
        className="EdituserHeading"
        sx={{
          fontWeight: "bold",
        }}
      >
        <Button onClick={handleGoBack}>
          <ArrowBackIcon fontSize="small" />
        </Button>
        {getPageHeading(page, existingusername)}
      </Grid>

      {/* main form section */}
      <Grid className="mainGrid">
        {/* basic details section */}
        <GetBasicDetails
          username={username}
          existinguseremail={existinguseremail}
          handleNameChange={handleNameChange}
          handleEmailChange={handleEmailChange}
        />

        {/* Autoclose */}
        <Grid className="autoClose">
          <h3>Additional Info</h3>
          <Divider />
          <Grid sx={{ color: "#3C3E49" }} container>
            <Grid sx={{ color: "#3C3E49" }} container>
              <Grid item xs={4} sx={{ lineHeight: "3.5em" }}>
                Squad name
              </Grid>
              <Grid sx={{ display: "grid" }} item xs={8}>
                <Grid justifySelf="flex-end" sx={{ padding: "12px 8px" }}>
                  {checkIfDisabled() ? (
                    <FormControl fullWidth>
                      <Select
                        // multiple
                        data-testid="my-select"
                        displayEmpty
                        disableUnderline
                        IconComponent={ExpandMoreIcon}
                        value={squadList}
                        onChange={handleChangeSquad}
                        renderValue={() => "Choose Squad"}
                        MenuProps={MenuProps}
                        onOpen={() => console.log("select opened")}
                        label="Age"
                        disabled
                        sx={{
                          // width: "8vw",
                          border: "none",
                          fontSize: "14px",
                        }}
                        variant="standard"
                      >
                        {squadsList.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            sx={{ padding: "0px" }}
                          >
                            <Radio checked={isSquadRadioBtnChecked(name)} />
                            <ListItemText
                              primary={name}
                              sx={{ fontSize: "14px" }}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <Select
                        // multiple
                        displayEmpty
                        disableUnderline
                        IconComponent={ExpandMoreIcon}
                        value={squadList}
                        onChange={handleChangeSquad}
                        renderValue={() => "Choose Squad"}
                        MenuProps={MenuProps}
                        onOpen={() => console.log("select opened")}
                        label="Age"
                        sx={{
                          // width: "8vw",
                          border: "none",
                          fontSize: "14px",
                        }}
                        variant="standard"
                      >
                        {squadsList.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            sx={{ padding: "0px" }}
                          >
                            <Radio checked={isSquadRadioBtnChecked(name)} />
                            <ListItemText
                              primary={name}
                              sx={{ fontSize: "14px" }}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>

                <Grid justifySelf="flex-end" sx={{ height: "2em" }}>
                  {" "}
                  <BasicDetailsCustomisedChips
                    customField={squadList}
                    type="Squad"
                    getDisabledChips={getDisabledChips}
                    getNormalChips={getNormalChips}
                  />
                </Grid>
              </Grid>
            </Grid>{" "}
          </Grid>{" "}
          <Grid sx={{ color: "#3C3E49" }} container>
            <Grid xs={11} item sx={{ lineHeight: "3em" }}>
              Is a manager
            </Grid>
            <Grid
              xs={1}
              item
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {checkIsAManager(handleManagerToggle, isManager)}
            </Grid>
          </Grid>{" "}
          <Grid sx={{ color: "#3C3E49", alignItems: "center" }} container>
            <Grid xs={11} item sx={{ lineHeight: "3em" }}>
              Color code
            </Grid>
            <Grid
              xs={1}
              item
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {checkColorPicker(handleChangeColor, colorCode)}
            </Grid>
          </Grid>{" "}
          <Grid sx={{ color: "#3C3E49" }} container>
            <Grid item xs={4} sx={{ lineHeight: "3.5em" }}>
              <span className="mandatoryAsterik">*</span>Teams
            </Grid>
            <Grid sx={{ display: "grid" }} item xs={8}>
              <Grid justifySelf="flex-end" sx={{ padding: "12px 8px" }}>
                {checkIfDisabled() ? (
                  <FormControl fullWidth>
                    <Select
                      data-testid="teamsSelect"
                      multiple
                      displayEmpty
                      disableUnderline
                      IconComponent={ExpandMoreIcon}
                      value={teamList}
                      onChange={handleChangeTeam}
                      renderValue={() => "Choose Team"}
                      MenuProps={MenuProps}
                      onOpen={() => console.log("select opened")}
                      label="Age"
                      disabled
                      sx={{
                        // width: "8vw",
                        border: "none",
                        fontSize: "14px",
                      }}
                      variant="standard"
                    >
                      {generateTeamsListDropdown(
                        teamsList,
                        teamList,
                        "empty_team",
                        "No teams available"
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl fullWidth>
                    <Select
                      multiple
                      displayEmpty
                      disableUnderline
                      IconComponent={ExpandMoreIcon}
                      value={teamList}
                      onChange={handleChangeTeam}
                      renderValue={() => "Choose Team"}
                      MenuProps={MenuProps}
                      onOpen={() => console.log("select opened")}
                      label="Age"
                      sx={{
                        // width: "8vw",
                        border: "none",
                        fontSize: "14px",
                      }}
                      variant="standard"
                    >
                      {generateTeamsListDropdown(
                        teamsList,
                        teamList,
                        "empty_team",
                        "No teams available"
                      )}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid justifySelf="flex-end" sx={{ height: "2em" }}>
                {" "}
                <BasicDetailsCustomisedChips
                  customField={teamList}
                  type="Team"
                  getDisabledChips={getDisabledChips}
                  getNormalChips={getNormalChips}
                />
              </Grid>
            </Grid>
          </Grid>{" "}
          <Grid sx={{ color: "#3C3E49" }} container>
            <Grid item xs={4} sx={{ lineHeight: "3.5em" }}>
              Sub Teams
            </Grid>
            <Grid sx={{ display: "grid" }} item xs={8}>
              <Grid justifySelf="flex-end" sx={{ padding: "12px 8px" }}>
                {checkIfDisabled() ? (
                  <FormControl fullWidth>
                    <Select
                      data-testid="subteam-select"
                      multiple
                      displayEmpty
                      disableUnderline
                      IconComponent={ExpandMoreIcon}
                      value={subTeamList}
                      onChange={handleChangeSubTeam}
                      renderValue={() => "Choose Sub Team"}
                      MenuProps={MenuProps}
                      onOpen={() => console.log("select opened")}
                      label="Age"
                      sx={{
                        // width: "8vw",
                        border: "none",
                        fontSize: "14px",
                      }}
                      variant="standard"
                      disabled
                    >
                      {generateTeamsListDropdown(
                        subTeamsList,
                        subTeamList,
                        "empty_sub_team",
                        "No sub teams available"
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl fullWidth>
                    <Select
                      multiple
                      displayEmpty
                      disableUnderline
                      IconComponent={ExpandMoreIcon}
                      value={subTeamList}
                      onChange={handleChangeSubTeam}
                      renderValue={() => "Choose Sub Team"}
                      MenuProps={MenuProps}
                      onOpen={() => console.log("select opened")}
                      label="Age"
                      sx={{
                        // width: "8vw",
                        border: "none",
                        fontSize: "14px",
                      }}
                      variant="standard"
                    >
                      {generateTeamsListDropdown(
                        subTeamsList,
                        subTeamList,
                        "empty_sub_team",
                        "No sub teams available"
                      )}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid justifySelf="flex-end" sx={{ height: "2em" }}>
                {" "}
                <BasicDetailsCustomisedChips
                  customField={subTeamList}
                  type="Sub Team"
                  getDisabledChips={getDisabledChips}
                  getNormalChips={getNormalChips}
                />
              </Grid>
            </Grid>
          </Grid>{" "}
        </Grid>

        {/* Other preferences */}
      </Grid>

      {/* Footer buttons */}
      <Grid className="footerButtons">
        <Grid className="saveCancelButton">
          <SaveCancel
            saveButtonStatus={saveButtonStatus}
            handleUserSave={handleUserSave}
            handleUserCancel={handleUserCancel}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EdituserPage;
