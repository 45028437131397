import { useState, useEffect, useRef } from "react";
import { customFetch, REACT_APP_API_PATH as baseURL } from "../../config";
import {
  redirectToTicketSumary,
  isManagerOrSuperUser,
} from "../../utils/common_functions";
import { ErrorComponent } from "../Error";
import TableWithExpandableRows from "../../components/TableWithExpandableRows";

function Teams() {
  const teamList = `${baseURL}v1/team/?limit=15`;
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableUrlNext, setTableUrlNext] = useState(teamList);

  const prevFetchUrl = useRef();

  const fetchData = async (customUrl) => {
    const fetchUrl = !customUrl ? teamList : customUrl;
    if (fetchUrl === prevFetchUrl.current || !fetchUrl) return;
    prevFetchUrl.current = fetchUrl;

    setIsLoading(true);
    const data = await customFetch(customUrl, "GET");
    if (data.next != null) setTableUrlNext(data.next);
    else setTableUrlNext("");
    setRowData(data.results);
    setIsLoading(false);
  };

  const fetchTeamsSeachList = async (searchText) => {
    setRowData([]);
    setIsLoading(true);
    const searchTeamData = await customFetch(
      teamList + `&search=${searchText}`,
      "GET"
    );
    setRowData(searchTeamData.results);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(tableUrlNext);
  }, []);

  const fetchMoreOnBottomReached = () => {
    if (tableUrlNext != "") fetchData(tableUrlNext);
  };
  return (
    <>
      {isManagerOrSuperUser() ? (
        <TableWithExpandableRows
          teamsData={rowData}
          isLoading={isLoading}
          fetchUsersSeachList={fetchTeamsSeachList}
          fetchMoreOnBottomReached={fetchMoreOnBottomReached}
        />
      ) : (
        <ErrorComponent
          errorMessage="You do not have access to view this page"
          handleRefresh={redirectToTicketSumary}
          firstButtonText="Go back"
        />
      )}
    </>
  );
}
export default Teams;
