import { Grid } from "@mui/material";
import { capitalizeFirstLetter } from "./clearAllSelectedFilters";
import { ChipWithMenu } from "../components/TeamsCountChips";

export const UsersAvailabilityCell = ({ row }) => {
  

    const availability = row.available ? 'Active' : 'Inactive';
  
    const circleColor = row.available ? "#008243" : "#595959";
  
    return (
      <Grid container className={`users${availability}`}>
        <Grid item>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="4" cy="4" r="4" fill={circleColor} />
          </svg>
        </Grid>
        <Grid item style={{ paddingLeft: "8px", fontFamily: "sans-serif" }}>
          {" "}
          {availability}
        </Grid>
      </Grid>
    );
  };

  export const getUserAvatar = (avatar,firstname,colorCode) => {
    if(avatar)
      return <img src={`${avatar}`} referrerPolicy={"no-referrer"} style={{
        height:"2rem",
        width:"2rem",
        borderRadius:"8px"
      }}/>
    else{
      const firstLetter = firstname.charAt(0).toUpperCase();
      return <div style={{
        width:'2rem',
        height:'2rem',
        backgroundColor:`${colorCode}`,
        color:'white',
        borderRadius:"8px",
        textAlign:"center",
        lineHeight:"2rem"
      }}>
        {firstLetter}
      </div>
    }
      
  }

  export const headerWidth = (label) => {
    if (label === "Actions") {
      return "6vw";
    } else if (label === "Username") {
      return "18vw";
    }
  };

  export const checkUserShift = (shift) => {
    if (
      Object.keys(shift).length === 0 ||
      !("working_days" in shift) ||
      shift.working_days.length === 0
    )
      return "N/A";
  
    return shift.working_days
      .map((item, index) => capitalizeFirstLetter(item).slice(0, 3))
      .join(" ");
  };

  export const getRemainingRelatedTeamsCount = (count, teamList) => {
    return count === 0 || count < 0 ? (
      ""
    ) : (
      <div>
        <ChipWithMenu count={count} menuItemList={teamList} />
      </div>
    );
  };