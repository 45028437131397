import { useState } from "react";
import ChangeUserShiftPage from "../../components/ChangeUserShiftPage";
import { ToastContainer } from "react-toastify";
import {
  successNotify,
  errorNotifyList,
} from "../../components/ToastComponent";
import { ErrorComponent } from "../Error";
import { customFetch, REACT_APP_API_PATH as baseURL } from "../../config";

const ChangeShift = () => {
  const [isLoading, setIsLoading] = useState(false);

  let page = "";

  page = "Change user shift";

  const locationObject = new URLSearchParams(window.location.search);
  const userID = locationObject.get("userid");
  const userShiftId = locationObject.get("userShiftId");


  const handleRefresh = () => {
    window.location.href = "/users";
  };

  const successNotifyandRedirect = (response_message) => {
    successNotify(response_message);
    setTimeout(() => {
      window.location.href = `/users`;
    }, 2000);
  };

  const fetchUserShiftData = async (
    getUserWorkingDays,
    setCheckboxes,
    getUserStartTime,
    getUserEndTime,
    handleRestChangeUserShift,
    checkboxes
  ) => {
    setIsLoading(true);
    const data = await customFetch(`${baseURL}v1/user/${userID}`);
   

    const userWorkingDays = getUserWorkingDays(data);


    if (userWorkingDays.length > 0) {
      const tempCheckboxes = checkboxes.map((item, index) => {
        const checkBoxDay = item.text;

        if (userWorkingDays.filter((item) => item === checkBoxDay).length > 0) {
          return {
            ...item,
            checked: true,
          };
        } else return item;
      });

      setCheckboxes(tempCheckboxes);
    }

    const updatedStartTime = getUserStartTime(data);
    const updatedEndTime = getUserEndTime(data);

 

    handleRestChangeUserShift(updatedStartTime, updatedEndTime, data);
    setIsLoading(false);
  };

  const updateUserShift = async (dataToBeSaved) => {
    const url = `${baseURL}v1/user_shift/${userShiftId}/`;

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: `Token ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToBeSaved),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.ok}`);
      }

      const updatedUser = await response.json();
     
      successNotifyandRedirect("Success: User shift updated successfully");

      return updatedUser;
    } catch (error) {
      console.error("Error updating user : ", error);
      errorNotifyList([
        "Error: Could not change user shift. Please try again after sometime",
      ]);
    }
  };

  return (
    <>
      {userShiftId ? (
        <>
          <ChangeUserShiftPage
            page={page}
            errorNotifyList={errorNotifyList}
            successNotify={successNotify}
            fetchUserShiftData={fetchUserShiftData}
            updateUserShift={updateUserShift}
            isLoading={isLoading}
          />
          <ToastContainer autoClose={2000} />
        </>
      ) : (
        <ErrorComponent
          errorMessage="User Shift does not exist. Please connect with your manager"
          handleRefresh={handleRefresh}
          firstButtonText="Go back"
        />
      )}
    </>
  );
};

export default ChangeShift;
